import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';

class PortadaImagesComponent extends Component {
    constructor() {
        super();
        this.state = {
            disabled: false,
            uploadValue: 0,
            selectedImages: {
                small: null,
                medium: null,
                large: null,
                xlarge: null
            },
            previewImages: {
                small: '',
                medium: '',
                large: '',
                xlarge: ''
            }
        };
    }

    handleImageChange = (event, size) => {
        const file = event.target.files[0];
        const previewUrl = URL.createObjectURL(file);

        this.setState(prevState => ({
            selectedImages: {
                ...prevState.selectedImages,
                [size]: file
            },
            previewImages: {
                ...prevState.previewImages,
                [size]: previewUrl
            }
        }));
    };

    handleSubmit = async () => {
        this.setState({ disabled: true, uploadValue: 0 });

        const { selectedImages } = this.state;

        // Define los paths para cada imagen
        const storagePaths = {
            small: '/portada/sizeS',
            medium: '/portada/sizeM',
            large: '/portada/sizeL',
            xlarge: '/portada/sizeXL'
        };

        try {
            // Crear un array para manejar el progreso de cada imagen
            const uploadProgress = {
                small: 0,
                medium: 0,
                large: 0,
                xlarge: 0
            };

            const uploadPromises = Object.keys(selectedImages).map((size) => {
                if (selectedImages[size]) {
                    const file = selectedImages[size];
                    const ref = firebase.storage().ref(`${storagePaths[size]}/portada.webp`);
                    const uploadTask = ref.put(file);

                    return new Promise((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                // Calcula el progreso individual de cada imagen
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                uploadProgress[size] = progress;

                                // Calcula el progreso total sumando los progresos individuales y promediando
                                const totalProgress = Object.values(uploadProgress).reduce((acc, val) => acc + val, 0) / Object.keys(selectedImages).length;
                                
                                this.setState({ uploadValue: totalProgress });
                            },
                            (error) => reject(error),
                            async () => {
                                const downloadURL = await ref.getDownloadURL();
                                console.log(`Imagen ${size} subida a: ${downloadURL}`);
                                resolve();
                            }
                        );
                    });
                }
                return Promise.resolve(); // Si no hay imagen para este tamaño, resolver de inmediato
            });

            // Espera a que todas las cargas se completen
            await Promise.all(uploadPromises);

            this.setState({ 
                disabled: false, 
                uploadValue: 100,
                selectedImages: {
                    small: null,
                    medium: null,
                    large: null,
                    xlarge: null
                },
                previewImages: {
                    small: '',
                    medium: '',
                    large: '',
                    xlarge: ''
                }
            });
            alert('Todas las imágenes han sido subidas con éxito.');
        } catch (error) {
            console.error('Error al subir las imágenes:', error);
            alert('Hubo un error al subir las imágenes.');
            this.setState({ disabled: false });
        }
    };

    render() {
        const { previewImages, uploadValue, disabled } = this.state;

        return (
            <div className="col-lg-11 mx-auto mt-4">
                <div className="card card-small edit-user-details mb-4">
                    <div className="card-body p-0">
                        <div className="form-row mx-4">
                            <div className="form-group col-md-3">
                                <label>Imagen pequeña (small) - Formato webp:</label>
                                {previewImages.small && (
                                    <div className="d-flex justify-content-center">
                                        <img src={previewImages.small} alt="Vista previa small" width="100" style={{marginBottom: "12px"}}/>
                                    </div>
                                )}
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={(e) => this.handleImageChange(e, 'small')} 
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Imagen mediana (medium) - Formato webp:</label>
                                {previewImages.medium && (
                                    <div className="d-flex justify-content-center">
                                        <img src={previewImages.medium} alt="Vista previa medium" width="100" style={{marginBottom: "12px"}}/>
                                    </div>
                                )}
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={(e) => this.handleImageChange(e, 'medium')} 
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Imagen grande (large) - Formato webp:</label>
                                {previewImages.large && (
                                    <div className="d-flex justify-content-center">
                                        <img src={previewImages.large} alt="Vista previa large" width="100" style={{marginBottom: "12px"}}/>
                                    </div>
                                )}
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={(e) => this.handleImageChange(e, 'large')} 
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label>Imagen extra grande (xlarge) - Formato webp:</label>
                                {previewImages.xlarge && (
                                    <div className="d-flex justify-content-center">
                                        <img src={previewImages.xlarge} alt="Vista previa xlarge" width="100" style={{marginBottom: "12px"}}/>
                                    </div>
                                )}
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={(e) => this.handleImageChange(e, 'xlarge')} 
                                />
                            </div>
                        </div>
                        {disabled && (
                            <div className="mb-3 progress" style={{ height: "5px", marginLeft: "30px", marginRight: "30px" }}>
                                <div 
                                    className="progress-bar bg-danger" 
                                    role="progressbar" 
                                    aria-valuemin="0" 
                                    aria-valuemax="100" 
                                    style={{ width: `${uploadValue}%` }} 
                                />
                            </div>
                        )}
                        <button onClick={this.handleSubmit} type="button" className="mb-2 btn btn-primary mr-2" disabled={disabled} style={{float: 'right'}}>
                            Subir Imágenes
                        </button>
                    </div>
                </div>                 
            </div>           
        );
    }
}

export default PortadaImagesComponent;
