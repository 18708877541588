import React, { Component } from 'react';

import PaginationComponent from './PaginationComponent';
import { GENERAL_STATUS, RESTOCKING_PAYMENT_STATUS } from '../utils/enums'
import { compare, updateArrows } from '../utils/table'
import HeaderComponent from './HeaderComponent';

var config = process.env;

class AnalyticsComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRowsPurchases: 10,
            quantityShowRowsRestockings: 10,
            showFromPurchases: 1,
            showToPurchases: 10,
            showFromRestockings: 1,
            showToRestockings: 10,
            purchases: [],
            restockings: [],
            filterPurchases: [],
            filterRestockings: [],
            itemName: "",
            loaded: false
        }
	}

	componentDidMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'analytics/product/' + this.props.code, request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    purchases: data.data.purchases,
                    restockings: data.data.restockings,
                    filterPurchases: data.data.purchases.sort(compare("date")).reverse(),
                    filterRestockings: data.data.restockings.sort(compare("date")).reverse(),
                    itemName: data.data.itemName,
                    loaded: true
                });
            }
        });
    }

    loadPurchases = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFromPurchases-1; i<this.state.showToPurchases; i++) {
                if (i < this.state.filterPurchases.length) {
                    items.push(this.drawPurchaseRow(this.state.filterPurchases[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay información</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    loadRestockings = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFromRestockings-1; i<this.state.showToRestockings; i++) {
                if (i < this.state.filterRestockings.length) {
                    items.push(this.drawRestockingRow(this.state.filterRestockings[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay información</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    extractDateParts = (fecha) => {
        const regex = /(\d{4})-(\d{2})-(\d{2})T/;
        const match = fecha.match(regex);
        if (match) {
          const year = match[1];
          const month = match[2];
          const day = match[3];
          return `${day}-${month}-${year}`;
        }
        return null;
      };

    drawPurchaseRow = (item, index) => {
          let date = this.extractDateParts(item.date)
        return(
            <tr role="row" class="odd" key={ "purchase-" + item.purchaseId } style={{background: ((index % 2 == 0) ? '#ffffff;' : '#fcfcfc')}}>
                <td>{item.purchaseId}</td>
                <td>{date}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>$ {item.price}</td>
                <td>{item.discount} %</td>
                <td>$ {item.price*(1-item.discount/100)}</td>
                <td>$ {item.cost}</td>
                <td>$ {(item.price*(1-item.discount/100) - item.cost )* item.quantity}</td>
                <td>{(((item.price*(1-item.discount/100) - item.cost ) / (item.price*(1-item.discount/100)) ) * 100).toFixed(2) } %</td>
                <td>{GENERAL_STATUS[item.status]}</td>
            </tr>
        );
    }

    drawRestockingRow = (item, index) => {
        let date = this.extractDateParts(item.date)
        return(
            <tr role="row" class="odd" key={ "purchase-" + item.id } style={{background: ((index % 2 == 0) ? '#ffffff;' : '#fcfcfc')}}>
                <td>{item.id}</td>
                <td>{date}</td>
                <td>{item.quantity}</td>
                <td>{item.unitPrice}</td>
                <td>{item.provider}</td>
                <td>{RESTOCKING_PAYMENT_STATUS[item.paymentStatus]}</td>
            </tr>
        );
    }

    onChangeShowRowsPurchases = () => {
        var itemSelected = document.getElementById("select_rows_pur").value;
        this.setState({ quantityShowRowsPurchases: itemSelected });
    }

    onChangeShowRowsRestocking = (e) => {
        var itemSelected = document.getElementById("select_rows_res").value;
        this.setState({ quantityShowRowsRestockings: itemSelected });
    }

    onChangeDataPaginationPurchases = (from, to) => {
        this.setState({ showFromPurchases: from, showToPurchases: to });
    }

    onChangeDataPaginationRestockings = (from, to) => {
        this.setState({ showFromRestockings: from, showToRestockings: to });
    }

    orderPurchases = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterPurchases.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterPurchases: newList });
    }

    
    orderRestockings = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterRestockings.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterRestockings: newList });
    }

    formatNumber = (num) => {
        if (num === '') return '';
        const parsedNumber = parseFloat(num.replace(/\./g, ''));
        if (isNaN(parsedNumber)) return '';
        return `$${parsedNumber.toLocaleString('de-DE')}`;
    };

    getDaysDifferenceNow = (date1) => {
        // Convertir las fechas a milisegundos
        const time1 = new Date(date1).getTime();
        const time2 = new Date().getTime();
        
        // Calcular la diferencia en milisegundos
        const differenceInMilliseconds = Math.abs(time2 - time1);
        
        // Convertir la diferencia a días
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        
        return differenceInDays.toFixed(0);
      }

    renderPurchasesStatistics = () => {
        if (this.state.loaded) {
            let sumPurchases = 0
            let unitsSold = 0
            let daysFromLastPurchase = 0
            if (this.state.purchases.length > 0)
            {
                let sortedByDate = this.state.purchases.sort(compare("date")).reverse()
                daysFromLastPurchase = this.getDaysDifferenceNow(sortedByDate[0].date)
            }
            else
            {
                if (this.state.restockings.length > 0)
                {
                    let firstRestocking = this.state.restockings.sort(compare("date"))
                    daysFromLastPurchase = this.getDaysDifferenceNow(firstRestocking[0].date)
                }                
            }
            
            this.state.purchases.forEach(purchase => {
                sumPurchases += purchase.price * purchase.quantity
                unitsSold += purchase.quantity
            })
            return (
            <div className="row align-items-center justify-content-center">    
                <div className="col-md-12 page-header page-title d-flex justify-content-center mb-4">  
                    <h3>{this.state.itemName}</h3>
                </div>     
                <div className="col-md-3 card card-small mb-4" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                    <div className="row">
                        <div className="col">  
                            <span class="text-muted d-block mb-2 text-center">Unidades vendidas</span>
                            <div className="col-md-12 d-flex align-items-center justify-content-center" style={{gap: '3px', fontSize: '20px'}}>
                                <span class="text-muted d-block mb-2">{unitsSold}</span>
                            </div>                                   
                        </div>
                    </div>
                </div>
                <div className="col-md-3 card card-small mb-4 ml-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                    <div className="row">
                        <div className="col">  
                            <span class="text-muted d-block mb-2 text-center">Monto total vendido</span>
                            <div className="col-md-12 d-flex align-items-center justify-content-center" style={{gap: '3px', fontSize: '20px'}}>
                                <span class="text-muted d-block mb-2">$ {sumPurchases.toLocaleString('de-DE')}</span>
                            </div>                                   
                        </div>
                    </div>
                </div>
                <div className="col-md-3 card card-small mb-4 ml-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                    <div className="row">
                        <div className="col">  
                            <span class="text-muted d-block mb-2 text-center">Días desde la última venta</span>
                            <div className="col-md-12 d-flex align-items-center justify-content-center" style={{gap: '3px', fontSize: '20px'}}>
                                <span class="text-muted d-block mb-2">{daysFromLastPurchase}</span>
                            </div>                                   
                        </div>
                    </div>
                </div>
                <div className="col-md-2 card card-small mb-4 ml-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                    <div className="row">
                        <div className="col">  
                            <span class="text-muted d-block mb-2 text-center">Unidades compradas</span>
                            <div className="col-md-12 d-flex align-items-center justify-content-center" style={{gap: '3px', fontSize: '20px'}}>
                                <span class="text-muted d-block mb-2">{this.state.restockings.reduce((sum, item) => sum + item.quantity,0)}</span>
                            </div>                                   
                        </div>
                    </div>
                </div> 
            </div>)
        }
    }

    renderPurchases = () => 
    {
        return <div>
        <HeaderComponent title="" subtitle="Ventas"/>
        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
            <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>Show 
                <select id="select_rows_pur" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRowsPurchases()}>
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select> 
                entries</label>
            </div>
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>Search:
                <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                </label>
            </div>
            <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
            <thead>
                <tr role="row">
                    <th onClick={(e) => this.orderPurchases(e, "purchaseId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Venta</th>
                    <th onClick={(e) => this.orderPurchases(e, "date")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Fecha</th>
                    <th onClick={(e) => this.orderPurchases(e, "name")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Cliente</th>
                    <th onClick={(e) => this.orderPurchases(e, "quantity")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Unidades</th>
                    <th onClick={(e) => this.orderPurchases(e, "price")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Precio</th>
                    <th onClick={(e) => this.orderPurchases(e, "discount")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Descuento</th>
                    <th onClick={(e) => this.orderPurchases(e, "finalPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Precio final</th>
                    <th onClick={(e) => this.orderPurchases(e, "cost")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Costo</th>
                    <th onClick={(e) => this.orderPurchases(e, "profit")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Ganancia bruta</th>
                    <th onClick={(e) => this.orderPurchases(e, "profit_percentage")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>%Ganancia</th>
                    <th onClick={(e) => this.orderPurchases(e, "status")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Estado</th>
                </tr>
            </thead>
            { this.loadPurchases() }
        </table>

            <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                <div className="loader"></div>
            </div>

            <PaginationComponent 
                totalItems={this.state.filterPurchases.length}
                quantityRows={this.state.quantityShowRowsPurchases}
                onChangeData={(from, to) => this.onChangeDataPaginationPurchases(from, to)}
            />
        </div>
    </div>         
    }

    renderRestockings = () => 
    {
        return <div>
        <HeaderComponent title="" subtitle="Restocks"/>
        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
            <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>Show 
                <select id="select_rows_res" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRowsRestocking()}>
                    <option selected value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select> 
                entries</label>
            </div>
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                <label>Search:
                <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                </label>
            </div>
            <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
            <thead>
                <tr role="row">
                    <th onClick={(e) => this.orderRestockings(e, "id")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Reposicion</th>
                    <th onClick={(e) => this.orderRestockings(e, "date")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Fecha</th>
                    <th onClick={(e) => this.orderRestockings(e, "quantity")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Unidades</th>
                    <th onClick={(e) => this.orderRestockings(e, "unitPrice")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Precio</th>
                    <th onClick={(e) => this.orderRestockings(e, "provider")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Proveedor</th>
                    <th onClick={(e) => this.orderRestockings(e, "paymentStatus")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Estado de pago</th>
                </tr>
            </thead>
            { this.loadRestockings() }
        </table>

            <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                <div className="loader"></div>
            </div>

            <PaginationComponent 
                totalItems={this.state.filterRestockings.length}
                quantityRows={this.state.quantityShowRowsRestockings}
                onChangeData={(from, to) => this.onChangeDataPaginationRestockings(from, to)}
            />
        </div>


    </div>         
    }

	render() {
        return (<div style={{padding: 15}}>    
            {this.renderPurchasesStatistics()}
            {this.renderPurchases()}
            {this.renderRestockings()}
        </div>)
    }
}

export default AnalyticsComponent;