import React, { Component } from 'react';

import PaginationComponent from './PaginationComponent';
import { INVOICE_TYPE} from './../utils/enums';
import { compare, updateArrows } from '../utils/table'

import { Calendar } from 'primereact/calendar';
import dateFormat from 'dateformat';

var config = process.env;

class TableInvoiceComponent extends Component {
    
    constructor(){
        super();
        this.state = {
            limit: 1000,
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false,
            dateSelected: null
        }
    }

    componentDidMount() {
        this.loadInvoices();
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="9" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay facturas emitidas</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    drawRow = (item, index) => {
        let cfeTypeInternalName = ""
        if(item.cfeType == 111)
        {
            cfeTypeInternalName = "eFact"
        }
        else if (item.cfeType == 101)
        {
            cfeTypeInternalName = "eTck"
        }
        item.tpoCambio = item.cfe[cfeTypeInternalName].Encabezado.Totales.TpoCambio
            
        return(
            <tr role="row" class="odd" key={ item._id } style={{background: (index % 2 == 0) ? '#ffffff' : '#fcfcfc' }}>
                <td>{item.clientEmissionId}</td>
                <td>{ INVOICE_TYPE[item.cfeType] }</td>
                <td>{ dateFormat(item.cfeDate, "dS mmmm, yyyy - HH:MM")  }</td>
                <td>{item.serie} {item.nro}</td>
                <td>$ {item.MntPagar*(item.tpoCambio)}</td>
                <td>{item.actualCfeStatus}</td>
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button
                            onClick={() => this.onViewInvoice(item._id)}
                            type="button"
                            className="btn btn-white">
                            <i class="material-icons">search</i>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    onViewInvoice = (invoiceId) => {
        window.open(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/file/' + invoiceId, '_blank');
    }
    
    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeLoadRows = () => {
        var itemSelected = document.getElementById("load_rows").value;
        this.setState({ limit: itemSelected }, () => {
            this.loadInvoices();
        });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            if (INVOICE_TYPE[this.state.data[i].cfeType]?.toLowerCase().includes(input.toLowerCase()) ||
            this.state.data[i].actualCfeStatus?.toLowerCase().includes(input.toLowerCase()) ||
            this.state.data[i].clientEmissionId?.toLowerCase().includes(input.toLowerCase()) ||
            this.state.data[i].nro?.toString().toLowerCase().includes(input.toLowerCase()) ||
            dateFormat(this.state.data[i].cfeDate, "dS mmmm, yyyy - HH:MM")?.toString().toLowerCase().includes(input.toLowerCase()) ||
            this.state.data[i].MntPagar?.toString().toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

    loadInvoices() {
        var request = {
            method: 'GET',
            headers: {
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'invoice/list/' + this.state.limit, request)
            .then(results => {
                return results.json();
            })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    this.setState({
                        data: data.data,
                        filterData: data.data,
                        loaded: true
                    });
                }
            });
    }

    onSetDate = (value) => {
        if(value !== null)
        {
            this.setState({ filterDate: value });
            let selectedDate = new Date(value)

            const coincidenMesYAnio = (fecha1, fecha2) => {
                return fecha1.getFullYear() === fecha2.getFullYear() && 
                    fecha1.getMonth() === fecha2.getMonth();
            };

            var newList = []
            for (var i=0; i<this.state.data.length; i++) {
                if (coincidenMesYAnio(selectedDate, new Date(this.state.data[i].cfeDate)))
                {
                    newList.push( this.state.data[i] );
                }
            }
            this.setState({ filterData: newList });
        }
        else
        {
            this.setState({ filterDate: null, filterData: this.state.data });
        }
    }

    sumarMontos = (cfeType) => {
        const { filterData } = this.state; // Asumiendo que filteredData está en el estado
        const result = filterData.reduce((acc, factura) => {
            // Condición para aplicar el filtro según el tipo de CFE
            if (cfeType === 0 || factura.cfeType == cfeType) {
                let cfeTypeInternalName = ""
                if(factura.cfeType == 111)
                    cfeTypeInternalName = "eFact"
                else if (factura.cfeType == 101)
                    cfeTypeInternalName = "eTck"

                acc.total += (parseInt(factura.MntPagar)*parseInt(factura.cfe[cfeTypeInternalName].Encabezado.Totales.TpoCambio) || 0); // Sumar MntPagar si cumple la condición
                acc.count++; // Incrementar el contador de ítems
                acc.iva += (factura.cfe[cfeTypeInternalName].Encabezado.Totales.MntIVATasaBasica*parseInt(factura.cfe[cfeTypeInternalName].Encabezado.Totales.TpoCambio)) || 0
                acc.totalSinIVA += (factura.cfe[cfeTypeInternalName].Encabezado.Totales.MntNetoIVATasaBasica*parseInt(factura.cfe[cfeTypeInternalName].Encabezado.Totales.TpoCambio)) || 0
                
            }
            return acc; // Retornar el acumulador modificado
        }, { total: 0, totalSinIVA: 0, count: 0, iva: 0 }); // Inicializamos el acumulador como un objeto
    
        return result; // Retornar el objeto con el total y la cantidad
    }

    renderGenericData = () => {
        return(
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Sumatoria</h6>
                    </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item p-3">
                    <div className="row">
                        <div className="col">
                            <div className="">
                                <div className="form-group col-md-12">
                                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <span style={{display: 'block' }}>Mes a sumar</span>
                                    </div>
                                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'15px' }}>
                                    <Calendar value={this.state.dateSelected} onChange={(e) => this.onSetDate(e.value)} view="month" dateFormat="mm/yy" showButtonBar />
                                    </div>                                    
                                </div>
                                <div className="row ">
                                    <div className="col-md-4">  
                                        <span className="text-muted d-block mb-2 text-center">Sumatoria total</span>
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="date">Total facturado sin IVA:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">$ {this.sumarMontos(0).totalSinIVA.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> {/* Muestra el total general */}
                                        </div>  
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="user">IVA total:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">$ {this.sumarMontos(0).iva.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> {/* Muestra la cantidad de emisiones */}
                                        </div> 
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="date">Total facturado con IVA:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">$ {this.sumarMontos(0).total.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> {/* Muestra el total general */}
                                        </div>  
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="user"># de emisiones:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">{this.sumarMontos(0).count}</span> {/* Muestra la cantidad de emisiones */}
                                        </div>   
                                    </div>
                                    <div className="col-md-4">  
                                        <span className="text-muted d-block mb-2 text-center">E-tickets</span>
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="date">Total facturado con iva:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">$ {this.sumarMontos(101).total.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> {/* Muestra el total para cfeType 101 */}
                                        </div>  
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="user"># e-tickets:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">{this.sumarMontos(101).count}</span> {/* Muestra la cantidad de e-tickets */}
                                        </div>   
                                    </div>
                                    <div className="col-md-4">  
                                        <span className="text-muted d-block mb-2 text-center">E-facturas</span>
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="date">Total facturado con iva:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">$ {this.sumarMontos(111).total.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span> {/* Muestra el total para cfeType 111 */}
                                        </div>  
                                        <div className="d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                            <label htmlFor="user"># e-facturas:</label>
                                            <span style={{ marginLeft: "30px" }} className="text-muted d-block mb-2">{this.sumarMontos(111).count}</span> {/* Muestra la cantidad de e-facturas */}
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
            </div>
        );
    }

    render() {
        return (  
            <div>
                {this.renderGenericData()}
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                    <div className="three_in_row dataTables_length" id="DataTables_Table_2_length">
                        <label>Cargar
                        <select id="load_rows" name="DataTables_Table_3_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeLoadRows()}>
                            <option value="0">Todas</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option selected value="1000">1000</option>
                        </select> 
                        facturas</label>
                    </div>
                    <div className="three_in_row dataTables_length" id="DataTables_Table_0_length">
                        <label>Mostrar 
                        <select id="select_rows" name="DataTables_Table_1_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option selected value="100">100</option>
                            <option value="500">500</option>
                        </select> 
                        facturas por página</label>
                    </div>
                    <div id="DataTables_Table_0_filter" className="three_in_row dataTables_filter">
                        <label>Search:
                        <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                        </label>
                    </div>

                    <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                        <thead>
                            <tr role="row">
                                <th onClick={(e) => this.orderData(e, "clientEmissionId")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Purchase</th>
                                <th onClick={(e) => this.orderData(e, "cfeType")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Tipo</th>
                                <th onClick={(e) => this.orderData(e, "cfeDate")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '20%'}}>Fecha</th>
                                <th onClick={(e) => this.orderData(e, "nro")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Nro factura</th>
                                <th onClick={(e) => this.orderData(e, "MntPagar")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Monto</th>
                                <th onClick={(e) => this.orderData(e, "actualCfeStatus")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Estado</th>
                                <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Acciones</th>
                            </tr>
                        </thead>
                        { this.loadData() }
                    </table>

                    <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                        <div className="loader"></div>
                    </div>

                    <PaginationComponent 
                        totalItems={this.state.filterData.length}
                        quantityRows={this.state.quantityShowRows}
                        onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                    />
                </div>
            </div>
        );
    }
}

export default TableInvoiceComponent;    