import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import FormBoxGameComponent from '../components/FormBoxGameComponent';
import firebase from '@firebase/app';
import '@firebase/storage';
import { validateSession } from  '../utils/functions';

var config = process.env;

class AddBoxGamePage extends Component {

	constructor(){
        super();
        this.state = {
            uploadValue: 0,
            checked: false,
            disabled: false,
            role: 0
        }
	}

    async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    handleChangeData = (value) => {
        this.setState({ disabled: true });
        var token = localStorage.getItem('token');
        var request = {
            method: 'POST',
            headers: { 
            'Content-Type': 'application/json',
            'access-token': token
            },
            body: JSON.stringify({
                prefix: value.prefix,
                name: value.name,
                price: parseFloat(value.price),
                discount: parseInt(value.discount),
                stock: parseInt(value.stock),
                minStock: parseInt(value.minStock),
                suggestionStock: parseInt(value.suggestionStock),
                description: value.description,
                type: parseInt(value.type),
                category: parseInt(value.category),
                gameDuration: parseInt(value.gameDuration),
                minPlayers: parseInt(value.minPlayers),
                maxPlayers: parseInt(value.maxPlayers),
                difficulty: parseInt(value.difficulty),
                cooperative: (value.cooperative  === 'true'),
                lonely: (value.lonely === 'true'),
                forTwo: (value.forTwo === 'true'),
                expansion: (value.expansion === 'true'),
                onlyUse: (value.onlyUse === 'true'),
                provider: parseInt(value.provider),
                relatedProducts: value.relatedProducts,
                externalItems: value.externalItems,
                sku: value.sku,
                display: (value.display === 'true'),
                barCode: parseInt(value.barCode),
                codeML: value.codeML,
                cost: parseInt(0),
                popularity: parseInt(value.popularidad)
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                this.setState({ disabled: false });
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({ uploadValue: 5 });
                this.uploadImages(value.images, data.data.code);
            }
        });
    }

    uploadImages = (images, code) => {
        var percentage = 0;
        var imagesUploaded = 0;
        var imagesToUpload = 0;
        var thumbnail = undefined;
        Object.keys(images).forEach((key) => {
            if (images[key] != undefined && images[key] != '' && images[key].path != '') {
                imagesToUpload += 1;
                if (thumbnail == undefined && images[key].thumb != '') {
                    imagesToUpload += 1;
                    thumbnail = images[key].thumb;
                }
            }
        });

        if (imagesUploaded === imagesToUpload) {
            this.redirect();
        } else {
            if (thumbnail != undefined) {
                fetch(thumbnail).then(res => res.blob()).then(blob => {
                    const storageRef = firebase.storage().ref(`/products/${code}/thumbnail`);
                    storageRef.put(blob);
                });
            }

            Object.keys(images).forEach((key) => {
                var image = images[key];
                if (image != undefined && image != '' && image.path != '') {
                    fetch(image.path)
                    .then(res => res.blob())
                    .then(blob => {
                    const storageRef = firebase.storage().ref(`/products/${code}/${image.position}`);
                    const task = storageRef.put(blob);
                    task.on('state_changed', (snapshot) => {
                        }, (error) => {
                            console.log(error);
                        }, () => {
                            imagesUploaded += 1;
                            percentage = (imagesUploaded / imagesToUpload) * 100;
                            this.setState({ uploadValue: percentage });
                            if (imagesUploaded === imagesToUpload) {
                                this.redirect();
                            }
                        });
                    });
                }
            });
        }    
    }
    
    redirect = () => {
        window.location.href = "/boxGames"
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={4} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />
                            <FormBoxGameComponent 
                                onChangeData={ this.handleChangeData } 
                                uploadValue={ this.state.uploadValue }
                                disabled={this.state.disabled}
                                role={this.state.role}
                                />
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddBoxGamePage;