import React, { Component } from 'react';

import PaginationComponent from './PaginationComponent';
import ModalPurchase from './ModalPurchase';
import { SHIPPING_STATUS } from '../utils/enums'
import { compare, updateArrows } from '../utils/table'
import { getTextColorClassShipping } from '../utils/formats'
import dateFormat from 'dateformat';
import HeaderComponent from './HeaderComponent';

var config = process.env;

class CashRegisterListComponent extends Component {

	constructor(){
        super();
        this.state = {
            purchaseId: 0,
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false
        }
	}

	componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'cashRegister', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data.sort(compare("day")).reverse(),
                    loaded: true
                });
            }
        });
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay información</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    drawRow = (item, index) => {
          const extractDateParts = (fecha) => {
            const regex = /(\d{4})-(\d{2})-(\d{2})T/;
            const match = fecha.match(regex);
            if (match) {
              const year = match[1];
              const month = match[2];
              const day = match[3];
              return `${day}-${month}-${year}`;
            }
            return null;
          };
          let date = extractDateParts(item.day)
        return(
            <tr role="row" class="odd" key={ item.day + item.user } style={{background: item.status == 0 ? ((index % 2 == 0) ? '#ffffff;' : '#fcfcfc') : "#e5ffe5" }}>
                <td tabindex="0" class="sorting_1">{ date }</td>
                <td>{item.user}</td>
                <td>{item.open_hour}</td>
                <td>{item.close_hour}</td>
                <td>{item.open_money}</td>
                <td>{item.close_money}</td>
                <td>{item.close_pos}</td>
                <td>{item.retirement}</td>
                <td>{item.status == 0 ? "CERRADA" : "ABIERTA"}</td>
                <td>{item.observations}</td>
            </tr>
        );
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    orderData = (e, order) => {
        console.log("Calling sort")
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div>
                <HeaderComponent title="" subtitle="Caja"/>
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label>Show 
                        <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option selected value="50">50</option>
                            <option value="100">100</option>
                        </select> 
                        entries</label>
                    </div>
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <label>Search:
                        <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                        </label>
                    </div>

                    <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                        <thead>
                            <tr role="row">
                                <th onClick={(e) => this.orderData(e, "day")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}} aria-sort="ascending">Dia</th>
                                <th onClick={(e) => this.orderData(e, "user")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Usuario</th>
                                <th onClick={(e) => this.orderData(e, "open_hour")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Apertura</th>
                                <th onClick={(e) => this.orderData(e, "close_hour")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Cierre</th>
                                <th onClick={(e) => this.orderData(e, "open_money")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Dinero apertura</th>
                                <th onClick={(e) => this.orderData(e, "close_money")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Dinero Cierre</th>
                                <th onClick={(e) => this.orderData(e, "close_pos")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Cierre POS</th>
                                <th onClick={(e) => this.orderData(e, "retirement")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>Retiro</th>
                                <th onClick={(e) => this.orderData(e, "status")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Estado</th>
                                <th onClick={(e) => this.orderData(e, "observations")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '25%'}}>Observaciones</th>
                            </tr>
                        </thead>
                        { this.loadData() }
                    </table>

                    <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                        <div className="loader"></div>
                    </div>

                    <PaginationComponent 
                        totalItems={this.state.filterData.length}
                        quantityRows={this.state.quantityShowRows}
                        onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                    />
                </div>
            </div>            
        );
    }
}

export default CashRegisterListComponent;