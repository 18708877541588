import React, { Component } from 'react';
import { convertToMoney } from '../utils/formats';

var config = process.env;

class ChangePurchaseComponent extends Component {
    
    constructor(){
        super();
        this.state = {
            items: {},
            initialItems: {},
            inFavour: 0
        }
    }
  

    componentWillMount() {
        this.loadProducts();
    }
    
    loadProducts = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/'+ this.props.originalPurchase, request)
        .then(results => { return results.json(); })
        .then(data => {
            let inFavour = 0
            data.data.purchase.products.forEach(item => {
                inFavour += item.quantity * item.price * (1 - (item.discount / 100))
            })
            this.setState({inFavour: inFavour, initialItems: this.convertProductsToDict(data.data.purchase.products), items: this.convertProductsToDict(data.data.purchase.products)})
            this.props.onChangeData(this.state.initialItems, inFavour);
        });
    }

    convertProductsToDict = (products) => {
        return products.reduce((dict, product) => {
            dict[product.code] = product;
            return dict;
        }, {});
    }

    drawTable = () => {
        return (
            <table id="change-purchase-table" style={{width: '100%'}}>
                <thead>
                    <tr role="row">
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '10%'}}>Código</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '30%'}}>Nombre</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '10%'}}>Cantidad</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '15%'}}>Precio</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '15%'}}>Descuento</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '20%'}}>Precio final</th>
                    </tr>
                </thead>                
                { this.drawTableItems() }
            </table>
        );
    }

    drawTableItems = () => {
        var map = this.state.items;
        var index = 0;
        var items = [];
        for (const [key, value] of Object.entries(map)) {
            items.push(this.drawRow(key, value, index));
            index++;
        }
        if (index == 0) {
            items.push(
                <tr><td colspan="6" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No tienes elementos</td></tr>
            )
        }
        return (<tbody>{ items }</tbody>)
    }

    upQuantity = (code) => {
        // Hacemos una copia del estado actual de los productos
        const products = { ...this.state.items };
        // Obtenemos la cantidad actual
        let quantity = parseInt(products[code].quantity);
        let inFavour = this.state.inFavour - quantity * products[code].price * (1 - (products[code].discount / 100))
        
        // Aumentamos la cantidad
        quantity += 1;
        
        // Comprobamos que no supere la cantidad inicial
        if (quantity <= this.state.initialItems[code].quantity) {
            // Actualizamos la cantidad en la copia del producto
            products[code] = { ...products[code], quantity };
            inFavour += quantity * products[code].price * (1 - (products[code].discount / 100))
            // Actualizamos el estado y llamamos a la función onChangeData con la copia actualizada
            this.setState({inFavour:inFavour, items: products }, () => {
                this.props.onChangeData(this.state.items, inFavour);
            });
        }
    }
    
    downQuantity = (code) => {
        // Hacemos una copia del estado actual de los productos
        const products = { ...this.state.items };
        
        // Obtenemos la cantidad actual
        let quantity = parseInt(products[code].quantity);
        
        let inFavour = this.state.inFavour - quantity * products[code].price * (1 - (products[code].discount / 100))
        // Reducimos la cantidad
        quantity -= 1;
        
        // Comprobamos que no sea menor que 0
        if (quantity >= 0) {
            // Actualizamos la cantidad en la copia del producto
            products[code] = { ...products[code], quantity };
            inFavour += quantity * products[code].price * (1 - (products[code].discount / 100))
            
            // Actualizamos el estado y llamamos a la función onChangeData con la copia actualizada
            this.setState({inFavour:inFavour, items: products }, () => {
                this.props.onChangeData(this.state.items, inFavour);
            });
        }
    }

    drawRow = (code, item, index) => {
        return(
            <tr role="row" class="odd table-purchase-item" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td class="table-purchase-item">{item.code}</td>
                <td class="table-purchase-item">{item.name}</td>      
                <td class="table-purchase-item">
                    <div class="row" style={{marginLeft: '0', marginRight: '0'}}>
                        <div class="col" style={{padding: '0', cursor: 'pointer'}} onClick={() => this.downQuantity(item.code)}>
                            <span class="input-group-text" style={{padding: '4px'}}>-</span>
                        </div>
                        <div class="col" style={{marginTop: '2px'}}>
                            {item.quantity}
                        </div>
                        <div class="col" style={{padding: '0', cursor: 'pointer'}} onClick={() => this.upQuantity(item.code)}>
                            <span class="input-group-text" style={{padding: '4px'}}>+</span>
                        </div>
                    </div>
                </td>
                <td class="table-purchase-item">{convertToMoney(item.price)}</td>
                <td class="table-purchase-item">{item.discount}</td>
                <td class="table-purchase-item">{convertToMoney(item.quantity * item.price * (1 - (item.discount / 100)))}</td>
            </tr>
        );
    }

    // Función de debounce
    debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    render() {
        return (
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div style={{fontSize: '24px', textAlign: 'center'}}>
                        <label>Seleccione cuales juegos de la compra se van a devolver</label>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                            { this.drawTable() }
                        </li>
                        <div style={{fontSize: '24px', textAlign: 'center'}}>
                            <label>Total a favor: </label>
                            <strong style={{marginLeft: '13px', color: "#0a780a"}}>$ <span id="total_price_in_favour">{convertToMoney(this.state.inFavour)}</span></strong>
                        </div>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ChangePurchaseComponent;