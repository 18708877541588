import React, { Component } from 'react';

import PaginationComponent from '../components/PaginationComponent';
import { compare, updateArrows } from '../utils/table'
import { getTextColorClassUsers, getRoleName } from '../utils/formats'

var config = process.env;

class TableProductsComponent extends Component {

	constructor(){
        super();
        this.state = {
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            data: [],
            filterData: [],
            loaded: false
		}
	}

	componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    data: data.data,
                    filterData: data.data,
                    loaded: true
                });
            }
        });
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay administradores</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    createModals = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=0; i<this.state.data.length; i++) {
                var data = this.state.data[i];
                items.push(
                    this.createModalRemove(data.email, data.firstName, data.lastName)
                );
                items.push(
                    this.createModalEdit(data.email, data.firstName, data.lastName, data.role)
                );
            }
            return (
                <div>{ items }</div>
            );
        }
    }

    createModalRemove = (mail, firstName, lastName) => {
        var email = mail.replace(".", "").replace("@", "").replace("-", "").replace("_", "")
        return (
            <div className="modal fade" id={`modal-remove-${email}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Eliminar</h4>
                        </div>
                        <div className="modal-body">
                            <p>¿Estás seguro que deseas eliminar al administrador?</p>
                            <p style={{marginBottom: '7px'}}><b>Nombre:</b> {firstName} {lastName}</p>
                            <p><b>Correo:</b> {mail}</p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onDeleteItem(mail)}>
                                Confirmar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createModalEdit = (mail, firstName, lastName, role) => {
        var email = mail.replace(".", "").replace("@", "").replace("-", "").replace("_", "")
        return (
            <div className="modal fade" id={`modal-edit-${email}`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Modificación de rol</h4>
                        </div>
                        <div className="modal-body">
                            <p>Que rol deseas asignar a {firstName} {lastName}</p>
                            <select id={`role-${email}`} className="custom-select" defaultValue={role}>
                                <option value="1">Administrador</option>
                                <option value="2">Encargado</option>
                                <option value="4">Finanzas</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                data-dismiss="modal"
                                onClick={() =>  this.onEditItem(mail)}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    drawRow = (item, index) => {
        var email = item.email.replace(".", "").replace("@", "").replace("-", "").replace("_", "")
        return(
            <tr role="row" class="odd" key={ item.code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td class={getTextColorClassUsers(item.role)}>{getRoleName(item.role)}</td>                
                <td>
                    <div class="btn-group btn-group-sm" role="group" aria-label="Table row actions">
                        <button 
                            data-toggle="modal"
                            data-target={`#modal-edit-${email}`}
                            type="button" 
                            class="btn btn-white"><i class="material-icons"></i>
                        </button>
                        <button 
                            data-toggle="modal"
                            data-target={`#modal-remove-${email}`}
                            type="button" 
                            class="btn btn-white">
                            <i class="material-icons"></i>
                        </button>
                    </div>
                </td>
            </tr>
        );
    }

    onDeleteItem = (email) => {
        if (this.state.data.length > 1) {
            var request = {
                method: 'DELETE',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.href = '/admin';
                }
            });
        } else {
            alert("Debe haber al menos un administrador")
        }
    }

    onEditItem = (email) => {
        var emailFormated = email.replace(".", "").replace("@", "").replace("-", "").replace("_", "")
        var role = document.getElementById(`role-${emailFormated}`).value;
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                email: email,
                role: parseInt(role) 
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'admin/updateRole', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                window.location.href = '/admin';
            }
        });
    }

    onUpdateItem = (code) => {
        window.location.href = `/editBoxGame?code=${code}`;
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = []
        for (var i=0; i<this.state.data.length; i++) {
            if (this.state.data[i].firstName.toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].lastName.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].phone.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].role.toString().toLowerCase().includes(input.toLowerCase()) ||
                this.state.data[i].email.toString().toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.data[i] );
            }
        }
        this.setState({ filterData: newList });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "firstName")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}} aria-sort="ascending">Nombre</th>
                            <th onClick={(e) => this.orderData(e, "lastName")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Apellido</th>
                            <th onClick={(e) => this.orderData(e, "email")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '30%'}}>Correo</th>
                            <th onClick={(e) => this.orderData(e, "phone")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Teléfono</th>
                            <th onClick={(e) => this.orderData(e, "role")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '15%'}}>Rol</th>
                            <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Acciones</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                { this.createModals() }

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default TableProductsComponent;