import React, { Component } from 'react';

import OpenCashComponent from '../components/OpenCashComponent';
import CloseCashComponent from '../components/CloseCashComponent';
import CashRegisterListComponent from '../components/CashRegisterListComponent';
import NavbarComponent from '../components/NavbarComponent';
import SidebarComponent from '../components/SidebarComponent';
import {validateSession, getCashRegister} from '../utils/functions';
var config = process.env;

class CajaPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0,
            cashRegisterStatus: 1,

        }
	}

	async componentWillMount() {
        let role = await validateSession(true)
        let cashRegister = await getCashRegister(true)
        let status = ""
        if (cashRegister!= undefined)
            status = cashRegister.data.status
        this.setState({ checked: true, role:  role, cashRegisterStatus: status});
    }

	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else { 
            
            let isSidebar = this.state.role == 1 || this.state.cashRegisterStatus == 1
            let mainWidth = isSidebar ? 10 : 12
            let offset = isSidebar ? 2 : 0
            return (  
                <div className="container-fluid">
                    <div className="row">
                        {isSidebar ? <SidebarComponent itemSelected={12} role={this.state.role}/> : ""}
                        <main className={`main-content col-lg-${mainWidth} col-md-${mainWidth} col-sm-${mainWidth} p-0 offset-lg-${offset} offset-md-${offset-1}`}>
                            <NavbarComponent role={this.state.role} />
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '80px'}}>    
                            <br></br>
                            {this.state.role == 1 ? <CashRegisterListComponent/>:""}
                            {this.state.cashRegisterStatus == 0 ? <OpenCashComponent/> : <CloseCashComponent/>}
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default CajaPage;