import React, { Component } from 'react';
import { convertToMoney } from '../utils/formats';
import NumberInput from './NumberInput';

var config = process.env;

class RestockingProductComponent extends Component {

    constructor(){
        super();
        this.state = {
            items: [],
            itemsFiltered: [],
            itemsSelected: {}
        }
    }

    componentWillMount() {
        this.loadProducts();
    }
    
    loadProducts = () => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product/forceAll', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({items: data.data, itemsFiltered: data.data})
            }
        });
    }
    
    onChangeItem = (e) => {
        var itemsSelected = this.state.itemsSelected;
        if (e.target.checked) {
            var item = undefined;
            this.state.items.forEach(element => {
                var code = `${element.prefix}${element.code}`
                if (code == e.target.id) {
                    item = {
                        prefix: element.prefix,
                        code: element.code,
                        name: element.name,
                        quantity: 1,
                        price: element.price,
                        cost: element.cost,
                        discount: element.discount,
                        productType: element.productType
                    }
                }
            })
            itemsSelected[e.target.id] = item;
        } else {
            delete itemsSelected[e.target.id]
        }
        //Update parent and state
        this.props.onChangeData(itemsSelected);
        this.setState({itemsSelected: itemsSelected});
    }

    loadItems = () => {
        var items = [];
        for(var i=0; i<this.state.itemsFiltered.length; i++) {
            var data = this.state.itemsFiltered[i];
            items.push(
                <div class="custom-control custom-checkbox mb-1">
                    <input onChange={(e) => this.onChangeItem(e)} key={`${data.prefix}${data.code}`} type="checkbox" class="custom-control-input" id={`${data.prefix}${data.code}`} defaultChecked={`${data.prefix}${data.code}` in this.state.itemsSelected}/>
                    <label class="custom-control-label" for={`${data.prefix}${data.code}`}>{ data.name } ({data.stock})</label>
                </div>
            );
        }
        return (
            <div className="items">{ items }</div>
        );
    }

    onChangeInput = () => {
        var input = document.getElementById("input_search").value;
        var newList = [];
        for (var i=0; i<this.state.items.length; i++) {
            var name = this.state.items[i].name;
            var code = this.state.items[i].prefix + this.state.items[i].code;
            if (name.toLowerCase().includes(input.toLowerCase()) || code.toString().toLowerCase().includes(input.toLowerCase())) {
                newList.push( this.state.items[i] );
            }
        }
        this.setState({ itemsFiltered: newList });
    }

    drawModal = () => {
        return(
            <div className="modal fade" id={`modal-products`} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Agregar producto</h4>
                            <div class="form-group" style={{marginBottom: '0'}}>
                                <input type="text" class="form-control" id="input_search" placeholder="Buscar" onChange={() => this.onChangeInput()}/>
                            </div>
                        </div>
                        <div className="modal-body" style={{maxHeight: '70vh', overflowY: 'auto'}}>
                            { this.loadItems() }
                        </div>                        
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                data-dismiss="modal">
                                Cerrar
                            </button>                      
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    drawTable = () => {
        return (
            <table id="add-purchase-table" style={{width: '100%'}}>
                <thead>
                    <tr role="row">
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '20%'}}>Código</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '30%'}}>Nombre</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '25%'}}>Cantidad</th>
                        <th className="table-purchase" rowspan="1" colspan="1" style={{width: '25%'}}>Costo unitario</th>
                    </tr>
                </thead>                
                { this.drawTableItems() }
            </table>
        );
    }

    drawTableItems = () => {
        var map = this.state.itemsSelected;
        var index = 0;
        var items = [];
        for (const [key, value] of Object.entries(map)) {
            items.push(this.drawRow(key, value, index));
            index++;
        }
        if (index == 0) {
            items.push(
                <tr><td colspan="6" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No tienes elementos</td></tr>
            )
        }
        return (<tbody>{ items }</tbody>)
    }

    drawRow = (code, item, index) => {
        return(
            <tr role="row" class="odd table-purchase-item" key={ code } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td class="table-purchase-item">{code}</td>
                <td class="table-purchase-item">{item.name}</td>
                <td class="table-purchase-item">
                    <NumberInput id={ `${code}_quantity` } defaultValue={ item.quantity } onChange={ () => this.onChangeQuantity(code) } />
                </td>
                <td class="table-purchase-item">
                    <NumberInput id={ `${code}_cost` } defaultValue={ item.cost } onChange={ () => this.onChangeCost(code) } />
                </td>
            </tr>
        );
    }

    onChangeQuantity = (code) => {
        var newQuantity = document.getElementById(`${code}_quantity`).value
        var items = this.state.itemsSelected;
        items[code].quantity = parseInt(newQuantity);
        //Update parent and state
        this.props.onChangeData(items);
        this.setState({ itemsSelected: items });
    }

    onChangeCost = (code) => {
        var newCost = document.getElementById(`${code}_cost`).value
        var items = this.state.itemsSelected;
        items[code].cost = parseInt(newCost);
        //Update parent and state
        this.props.onChangeData(items);
        this.setState({ itemsSelected: items });
    }

    calculateTotalPrice = () => {
        const itemsSelected = this.state.itemsSelected;
        let totalPrice = 0;
      
        for (const key in itemsSelected) {
          if (itemsSelected.hasOwnProperty(key)) {
            const item = itemsSelected[key];
            totalPrice += item.quantity * item.cost;
          }
        }
      
        return totalPrice;
    }

     // Función de debounce
     debounce = (func, delay) => {
        let timeoutId;
        return function(...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    // Función para manejar el cambio en el input con debounce
    handleBarcodeChange = this.debounce((event) => {
        const barCode = event.target.value;
        if (barCode != null && barCode != undefined && barCode != 0 && barCode != "")
        {
            var itemsSelected = this.state.itemsSelected;
            var item = undefined;
            this.state.items.forEach(element => {
                if (element.barCode == barCode) {
                    if (itemsSelected[element.prefix+element.code] != null)
                    {
                        let quantity = itemsSelected[element.prefix+element.code].quantity
                        item = {... itemsSelected[element.prefix+element.code] , quantity : quantity +1}
                    }
                    else{
                        item = {
                            prefix: element.prefix,
                            code: element.code,
                            name: element.name,
                            quantity: 1,
                            price: element.price,
                            discount: element.discount,
                            productType: element.productType
                        }
                    }                   
                    itemsSelected[element.prefix+element.code] = item;
                    this.props.onChangeData(itemsSelected);
                    this.setState({itemsSelected: itemsSelected});
                    document.getElementById("barCode").value = "";
                }
            })
            
        }        
    }, 150);

    render() {
        const totalPrice = this.calculateTotalPrice();

        return (
            <div className="col-lg-12">
                <div className="card card-small mb-4">
                    <div className="card-header border-bottom">
                        <div className="row">
                            <span className="col-lg-4" >Productos</span>
                            <input autoComplete="off" className="col-lg-4 form-control" type="text" id="barCode" placeholder="Código de barras" onChange={(e) => this.handleBarcodeChange(e)}/>
                            <button data-toggle="modal" data-target={`#modal-products`} type="button" className="col-lg-4 btn btn-outline-primary" style ={{float: 'right'}}>Agregar</button>
                         </div>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item p-3">
                            { this.drawTable() }
                            
                            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                                <h6>Precio Total: $ {convertToMoney(totalPrice)}</h6>
                            </div>
                        </li>
                    </ul>
                </div>
                { this.drawModal() }
            </div>
        );
    }
}

export default RestockingProductComponent;