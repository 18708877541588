import React, { Component } from 'react';

import PaginationComponent from './PaginationComponent';
import { compare, updateArrows } from '../utils/table'

var config = process.env;

class GeneralStatisticsComponent extends Component {

	constructor(){
        super();
        this.state = {
            data: [],
            quantityShowRows: 50,
            showFrom: 1,
            showTo: 10,
            filterData: [],
            loaded: false
		}
	}

	componentWillMount() {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'analytics/general', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                this.setState({
                    data: data.data.purchasesByMonth,
                    filterData: data.data.purchasesByMonth.sort(compare("date")).reverse(),
                    loaded: true
                });
            }
        });
    }

    loadData = () => {
        if (this.state.loaded) {
            var items = [];
            for(var i=this.state.showFrom-1; i<this.state.showTo; i++) {
                if (i < this.state.filterData.length) {
                    items.push(this.drawRow(this.state.filterData[i], i));
                }
            }
            if (items.length == 0) {
                items.push(
                    <tr><td colspan="7" style={{textAlign: 'center', padding: '10px', border: '1px solid rgb(225, 229, 235)'}}>No hay datos</td></tr>
                )
            }
            return (
                <tbody>{ items }</tbody>
            );
        } else {
            return ( <tbody></tbody> );
        }
    }

    drawRow = (item, index) => {
        return(
            <tr role="row" class="odd" key={ item.date } style={{background: (index % 2 == 0) ? '#ffffff;' : '#fcfcfc' }}>
                <td tabindex="0" class="sorting_1">{item.date}</td>
                <td>{item.totalCount}</td>
                <td>{item.totalUnits}</td>
                <td>{(item.totalUnits/item.totalCount).toFixed(2)}</td>
                <td>$ {item.promedio.toFixed(0).toLocaleString('de-DE')}</td>
                <td>$ {item.totalSum.toLocaleString('de-DE')}</td>
                <td>$ {item.totalCost ? item.totalCost.toLocaleString('de-DE'): 0}</td>
                <td>$ {item.totalCost ? (item.totalSum - item.totalCost).toLocaleString('de-DE') : "0" }</td>
                <td>$ {item.totalCost ? ((item.totalSum - item.totalCost)/item.totalCount).toFixed(0).toLocaleString('de-DE') : "0" }</td>
                <td>{item.totalCost ? (((item.totalSum - item.totalCost)/item.totalSum)*100).toFixed(2).toLocaleString('de-DE') : "0" } %</td>
            </tr>
        );
    }

    onChangeShowRows = () => {
        var itemSelected = document.getElementById("select_rows").value;
        this.setState({ quantityShowRows: itemSelected });
    }

    onChangeDataPagination = (from, to) => {
        this.setState({ showFrom: from, showTo: to });
    }

    orderData = (e, order) => {
        let value = updateArrows(e)
        var newList = this.state.filterData.sort(compare(order));
        if (value == "sorting_desc") {
            newList.reverse();
        }
        this.setState({ filterData: newList });
    }

	render() {
        return (  
            <div style={{"margin": "15px"}} id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show 
                    <select id="select_rows" name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" onChange={() => this.onChangeShowRows()}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option selected value="50">50</option>
                        <option value="100">100</option>
                    </select> 
                    entries</label>
                </div>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                    <label>Search:
                    <input id="input_search" type="search" className="" placeholder="" aria-controls="DataTables_Table_0" onChange={() => this.onChangeInput()}/>
                    </label>
                </div>

                <table className="transaction-history d-none dataTable no-footer dtr-inline" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info" >
                    <thead>
                        <tr role="row">
                            <th onClick={(e) => this.orderData(e, "date")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}} aria-sort="ascending">Mes</th>
                            <th onClick={(e) => this.orderData(e, "totalCount")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>#Ventas</th>
                            <th onClick={(e) => this.orderData(e, "totalUnits")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '5%'}}>#Productos</th>
                            <th onClick={(e) => this.orderData(e, "productsPerSale")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>#Productos por venta</th>
                            <th onClick={(e) => this.orderData(e, "promedio")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Promedio de venta</th>
                            <th onClick={(e) => this.orderData(e, "totalSum")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '13%'}}>Total vendido</th>
                            <th onClick={(e) => this.orderData(e, "totalCost")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Costo total</th>
                            <th onClick={(e) => this.orderData(e, "superavit")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Ganancia bruta</th>
                            <th onClick={(e) => this.orderData(e, "avgEarning")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>Ganancia promedio</th>
                            <th onClick={(e) => this.orderData(e, "percentage")} className="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{width: '10%'}}>% Bruto de ganancia</th>
                        </tr>
                    </thead>
                    { this.loadData() }
                </table>

                <div className="containerLoader" style={{display:this.state.loaded==true?'none':'block'}}>
                    <div className="loader"></div>
                </div>

                <PaginationComponent 
                    totalItems={this.state.filterData.length}
                    quantityRows={this.state.quantityShowRows}
                    onChangeData={(from, to) => this.onChangeDataPagination(from, to)}
                />
            </div>
        );
    }
}

export default GeneralStatisticsComponent;