
var config = process.env;

export function validateSession(dontCheckCashRegister=false)
{
    var request = {
        method: 'POST',
        headers: { 
            'access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }
    };
    return fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkSessionAdmin', request)
    .then(results => { return results.json(); })
    .then(data => {
        if (data.error == 0 && data.data.isValidToken) {

            if (hasPermissions(data.data.role))
            {
                if(dontCheckCashRegister)
                {
                    return data.data.role
                }                
                else
                {
                    if(data.data.role != 1)
                    {
                        checkCashRegisterClosure()
                        return data.data.role
                    }
                    else 
                    {
                        return data.data.role
                    }
                } 
            } 
            else 
            {
                window.location.href = config.REACT_APP_REDIRECT_LOGIN;
            }            
        } else {
            window.location.href = config.REACT_APP_REDIRECT_LOGIN;
        }
    });
}

function hasPermissions(role)
{
    let result = true
    if (role === 4)
    {
        let url = new URL(window.location.href);
        let lastPart = url.pathname.split('/').pop();
        if(lastPart != "invoices")
            result = false
    }
    return result
}

export async function getCashRegister() {
    var request = {
        method: 'GET',
        headers: { 
            'access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }
    };

    try {
        const response = await fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkCashRegister', request);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error al obtener el estado de la caja:', error);
        throw error;
    }
}

export async function checkCashRegisterClosure() {
    try {
        let data = await getCashRegister();
        console.log(data);
        if (data.error === 0 && data.data.status === 0) {
            window.location.href = "/caja";
        }
    } catch (error) {
        console.error('Error al verificar el estado de la caja:', error);
    }
}

export async function getProviders(providerType){
    var request = {
        method: 'GET',
        headers: { 
            'access-token': localStorage.getItem('token'),
            'Content-Type': 'application/json',
        }
    };
    return await fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'provider/' + providerType, request)
    .then(results => { return results.json(); })
    .then(data => {
        if (data.error > 0) {
            alert(data.message);
            return []
        } else {
           return data.data
        }
    });
}

export function formatNumber(num) {
    if (num === '') return '';
    const parsedNumber = parseFloat(num.replace(/\./g, ''));
    if (isNaN(parsedNumber)) return '';
    return `$${parsedNumber.toLocaleString('de-DE')}`;
};