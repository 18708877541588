import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import DiscountCodeProductComponent from '../components/DiscountCodeProductComponent';
import '@firebase/storage';
import { validateSession } from  '../utils/functions';

var config = process.env;

class AddDiscountCodePage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false,
            productsDisabled: false,
            products: []
        }
	}

    async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    checkIsValid = (input) => {
        if (input.value == null || input.value == undefined || input.value == '') {
            input.className = "form-control is-invalid";
            return true;
        } else {
            input.className = "form-control";
            return false;
        }
    }

    onClickSave = () => {
        var code = document.getElementById("code");
        var discount = document.getElementById("discount");
        var from = document.getElementById("from");
        var to = document.getElementById("to");
        var any = document.getElementById("any");
        var max_amount = document.getElementById("max_amount");
        var unique = document.getElementById("unique");
        var type = document.getElementById("type");

        var products = [];
        var productsState = this.state.products;
        Object.keys(productsState).forEach((key) => {
            products.push(productsState[key].code);
        });
        
        var hasError = false;
        hasError = this.checkIsValid(code) || hasError;
        hasError = this.checkIsValid(any) || hasError;
        hasError = this.checkIsValid(unique) || hasError;
        hasError = this.checkIsValid(type) || hasError;
        if (type.value == 1) {
            hasError = this.checkIsValid(discount) || hasError;
        }

        var maxAmount = 0;
        if (max_amount.value !== undefined && max_amount.value !== null && max_amount.value !== '') {
            maxAmount = max_amount.value
        }

        if (!hasError) {
            this.setState({ disabled: true });
            var request = {
                method: 'POST',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: code.value,
                    discount: parseInt(discount.value),
                    from: from.value,
                    to: to.value,
                    any: (any.value  === 'true'),
                    products: products,
                    max_amount: parseInt(maxAmount),
                    unique: (unique.value  === 'true'),
                    type: parseInt(type.value),
                    used: false
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'discountCode', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    this.setState({ disabled: false });
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.href = "/discountCodes";
                }
            });
        }
    }

    change = (event) =>{
        let value = event.target.value == "true"
        this.setState({productsDisabled: value});
    }

    handlerSelectProduct = (value) => {
        this.setState({products: value});
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={11} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />                            
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Dashboard" subtitle="Agregar código de descuento"/>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Datos del descuento</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-4">
                                                                    <label for="code">Código</label>
                                                                    <input type="text" className="form-control" id="code" placeholder="Código"/>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="discount">Descuento</label>
                                                                    <input type="number" className="form-control" id="discount" placeholder="Ingrese el monto de descuento" defaultValue={0}/>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="any">Aplica a todos?</label>
                                                                    <select id="any" className="custom-select" onChange={this.change} value={this.state.productsDisabled}>
                                                                        <option value={true} >Si</option>
                                                                        <option value={false} selected>No</option>
                                                                    </select>
                                                                </div>   
                                                                <div className="form-group col-md-6">
                                                                    <label for="from">Fecha desde</label>
                                                                    <input type="date" className="form-control" id="from" placeholder="Ingrese fecha desde"/>
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label for="to">Fecha hasta</label>
                                                                    <input type="date" className="form-control" id="to" placeholder="Ingrese fecha hasta"/>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">                                                         
                                                                <div className="form-group col-md-4">
                                                                    <label for="max_amount">Descuento máximo</label>
                                                                    <input type="number" className="form-control" id="max_amount" placeholder="Maximo descuento en pesos"/>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="unique">Uso único?</label>
                                                                    <select id="unique" className="custom-select">
                                                                        <option value={true} >Si</option>
                                                                        <option value={false} selected>No</option>
                                                                    </select>
                                                                </div>
                                                                <div className="form-group col-md-4">
                                                                    <label for="type">Tipo de descuento</label>
                                                                    <select id="type" className="custom-select">
                                                                        <option value={1} selected>Estandar</option>
                                                                        <option value={2}>3 x 2</option>
                                                                    </select>
                                                                </div> 
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6" style={{display: this.state.productsDisabled ? "none" : "block"}}>
                                        <DiscountCodeProductComponent onChangeData={ this.handlerSelectProduct }/>
                                    </div>

                                </div>
                                <div onClick={() => this.onClickSave()} class="color-switcher-toggle animated pulse infinite">
                                    <i class="material-icons">save</i>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddDiscountCodePage;