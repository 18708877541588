import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import PurchaseProductComponent from '../components/PurchaseProductComponent';
import PurchaseShippingComponent from '../components/PurchaseShippingComponent';
import PurchasePaymentComponent from '../components/PurchasePaymentComponent';
import ChangePurchaseComponent from '../components/ChangePurchaseComponent';

import { convertToMoney } from '../utils/formats';
import { validateSession } from  '../utils/functions';

var config = process.env;

class AddPurchasePage extends Component {

    constructor() {
        super();
        this.state = {
            msgError: "",
            showError: false,
            enabled: true,
            products: {},
            changedProducts: {},
            payment: {
                method: 5,
                promo_code: ""
            },
            shippingMethod: 2,
            total_price: 0,
            change: 0,
            inFavour: 0,
            finalPrice: 0
        }
    }

    async componentDidMount() {
        let role = await validateSession()

        const searchParams = new URLSearchParams(this.props.location.search);
        const changeParam = searchParams.get('change');

        this.setState({ 
            checked: true, 
            role:  role,
            change: changeParam ? parseInt(changeParam) : 0});
    }

    updatePrice = (products, payment) => {
        var code = payment.promo_code;
        var productsList = [];
        for (const [key, value] of Object.entries(products)) {
            productsList.push({
                quantity: value.quantity,
                price: value.price,
                discount: value.discount,
                code: value.code
            });
        }
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code: code,
                products: productsList
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/calculatePrice', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                var finalPrice = convertToMoney(data.data.price.final_price);
                var originalPrice = convertToMoney(data.data.price.original_price);
                if (finalPrice != originalPrice) {
                    document.getElementById("total_price").innerHTML = originalPrice;
                } else {
                    document.getElementById("total_price").innerHTML = ""   
                }
                document.getElementById("total_price_discount").innerHTML = finalPrice;
                this.setState({finalPrice: data.data.price.final_price})
            }
        });
    }

    handlerSelectProduct = (value) => {
        this.setState({products: value});
        this.updatePrice(value, this.state.payment)
    }
    
    handlerSelectChangedProduct = (value, inFavour) => {
        this.setState({changedProducts: value, inFavour: inFavour});
        this.updatePrice(this.state.products, this.state.payment)
    }

    handlerSelectPayment = (value) => {
        this.setState({payment: value});
        this.updatePrice(this.state.products, value)
    }

    handlerSelectShipping = (value) => {
        this.setState({
            shippingMethod: value
        });
    }

    validateData = () => {
        console.log("startValidation")
        if (!this.state.enabled) {
            console.log("not enabled")
            return;
        }
        
        var size = Object.getOwnPropertyNames(this.state.products).length;
        if (size == 0) {
            this.setState({
                msgError: "Agrega al menos un producto",
                showError: true
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        } else {
            this.setState({ showError: false });
        }
        
        var hasError = false;
        var firstname = document.getElementById("firstname");
        var lastname = document.getElementById("lastname");
        var address = document.getElementById("address");
        var cedula = document.getElementById("cedula");
        var isCompany = document.getElementById("isCompany");
        var rut = document.getElementById("rut");
        var razonSocial = document.getElementById("razonSocial");
        var direccionFiscal = document.getElementById("direccionFiscal");

        if (cedula.value == undefined || cedula.value == "") {
            cedula.classList.add("is-invalid");
            hasError = true;
        } else {
            cedula.classList.remove("is-invalid");
        }
        if (firstname.value == undefined || firstname.value == "") {
            firstname.classList.add("is-invalid");
            hasError = true;
        } else {
            firstname.classList.remove("is-invalid");
        }
        if (lastname.value == undefined || lastname.value == "") {
            lastname.classList.add("is-invalid");
            hasError = true;
        } else {
            lastname.classList.remove("is-invalid");
        }
        if (address.value == undefined || address.value == "") {
            address.classList.add("is-invalid");
            if (this.state.shippingMethod == 1) {
                hasError = true;
            }
        } else {
            address.classList.remove("is-invalid");
        }

        if (isCompany.checked) {
            if (rut.value == undefined || rut.value == "") {
                rut.classList.add("is-invalid");
                hasError = true;
            } else {
                rut.classList.remove("is-invalid");
            }
            if (razonSocial.value == undefined || razonSocial.value == "") {
                razonSocial.classList.add("is-invalid");
                hasError = true;
            } else {
                razonSocial.classList.remove("is-invalid");
            }
            if (direccionFiscal.value == undefined || direccionFiscal.value == "") {
                direccionFiscal.classList.add("is-invalid");
                hasError = true;
            } else {
                direccionFiscal.classList.remove("is-invalid");
            }
        }

        if (hasError) {
            console.log("hasError")
            return;
        }

        this.insertUser()
    }

    insertUser = () => {
        this.setState({enabled: false, msgError: "", showError: false});
        
        var department = document.getElementById("department").value;
        var city = document.getElementById("city").value;
        var address = document.getElementById("address").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;

        var isCompany = document.getElementById("isCompany");
        
        var rut = "";
        var razonSocial = "";
        var direccionFiscal = "";

        if (this.state.shippingMethod == 2) {
            city = "";
            department = "";
            address = "";
        }

        if (isCompany.checked) {
            rut = document.getElementById("rut").value;
            razonSocial = document.getElementById("razonSocial").value;
            direccionFiscal = document.getElementById("direccionFiscal").value;
        }

        if (email == undefined) {
            email = "";
        }
        if (phone == undefined) {
            phone = "";
        }

        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                firstName: document.getElementById("firstname").value,
                lastName: document.getElementById("lastname").value,
                phone: phone,
                email: email,
                cedula: document.getElementById("cedula").value,
                password: "",
                address: address,
                city: city,
                department: department,
                rut: rut,
                razonSocial: razonSocial,
                direccionFiscal: direccionFiscal
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'user/insertOrUpdate', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error != 0) {
                this.setState({enabled: true, msgError: data.message, showError: true});
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                // Insert purchase
                this.insertPurchase()
            }
        });
    }

    insertPurchase = () => {
        var products = [];
        for (const [key, value] of Object.entries(this.state.products)) {
            products.push({
                prefix: value.prefix,
                code: value.code,
                quantity: value.quantity,
                productType: value.productType
            });
        }
        var providerId = -1;
        if (document.getElementById("provider").value != undefined && document.getElementById("provider").value != '') {
            providerId = parseInt(document.getElementById("provider").value);
        }

        var isCompany = document.getElementById("isCompany");
        var rut = "";
        if (isCompany.checked) {
            rut = document.getElementById("rut").value;
        }
        
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cedula: document.getElementById("cedula").value,
                paymentType: this.state.payment.method,
                discountCode: this.state.change === 0 ? document.getElementById("promo_code").value: "",
                products: products,
                shippingType: this.state.shippingMethod,
                shippingPrice: 0,
                shippingHourPreference: document.getElementById("hour_preference").value,
                shippingAddress: document.getElementById("address").value,
                shippingCity: document.getElementById("city").value,
                shippingDepartment: document.getElementById("department").value,
                paymentData: "",
                provider: providerId,
                rut: rut
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error != 0) {
                console.log(data);
                this.setState({enabled: true, msgError: data.message, showError: true});
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                if(this.state.change)
                {
                    this.insertChange(data.data.external_reference)
                }
                else
                    window.location.href = "/purchase";
            }
        });
    }

    insertChange = (newPurchaseId) => {
        let changedProductsArray = []
        for(let key in this.state.changedProducts)
        {
            changedProductsArray.push(this.state.changedProducts[key])
        }
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                newPurchaseId: newPurchaseId.toString(),
                changedPurchaseId: this.state.change.toString(),
                changedPurchaseItems: changedProductsArray
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'purchase/change', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error != 0) {
                console.log(data);
                this.setState({enabled: true, msgError: data.message, showError: true});
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                window.location.href = "/purchase";
            }
        });
    }

    render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            let subtitle = "Nueva venta"
            if(this.state.change > 0)
                subtitle = "Nuevo Cambio"
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={8} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />
                            <div className="alert alert-danger alert-dismissible fade show mb-0" style={{display: this.state.showError?"block":"none"}}>{ this.state.msgError }</div>
                            <div className="main-content-container container-fluid px-4 mb-4" style={{paddingBottom: '40px'}}>
                                <HeaderComponent title="Venta" subtitle={subtitle}/>
                                <div className="row">
                                    {this.state.change != 0 ? 
                                    <ChangePurchaseComponent onChangeData={ this.handlerSelectChangedProduct } originalPurchase={this.state.change} /> : ""
                                    }
                                    <PurchaseProductComponent onChangeData={ this.handlerSelectProduct } isPurchaseChange={this.state.change !== 0}/>
                                    <PurchaseShippingComponent onChangeData={ this.handlerSelectShipping }/>
                                    <PurchasePaymentComponent onChangeData={ this.handlerSelectPayment } isPurchaseChange={this.state.change !== 0}/>
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div style={{fontSize: '24px', textAlign: 'center'}}>
                                                <label>Precio final: </label>
                                                <strong style={{marginLeft: '13px'}}>$ <span id="final_price">{convertToMoney(this.state.finalPrice - this.state.inFavour)}</span></strong>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                                
                                <button 
                                    disabled={!this.state.enabled}
                                    type="button" 
                                    class="mb-2 btn btn-primary" 
                                    style={{float: 'right', width: '200px', marginBottom: '25px !important'}} 
                                    onClick={() => this.validateData() }>
                                        {this.state.enabled?'Confirmar':<div className="min-loader"></div>}
                                </button>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddPurchasePage;