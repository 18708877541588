import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import { validateSession } from  '../utils/functions';
import '@firebase/storage';

var config = process.env;

class AddLabelPage extends Component {

	constructor(){
        super();
        this.state = {
            disabled: false
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    checkIsValid = (input) => {
        if (input.value == null || input.value == undefined || input.value == '') {
            input.className = "form-control is-invalid";
            return true;
        } else {
            input.className = "form-control";
            return false;
        }
    }

    onClickSave = () => {
        var name = document.getElementById("name");
        
        var hasError = false;
        hasError = this.checkIsValid(name) || hasError;

        if (!hasError) {
            this.setState({ disabled: true });
            var request = {
                method: 'POST',
                headers: { 
                    'access-token': localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name.value,
                })
            };
            fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'label', request)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.error > 0) {
                    alert(data.message);
                    this.setState({ disabled: false });
                    if (data.error == 1002) {
                        window.location.href = localStorage.getItem('homePageURL');
                    }
                } else {
                    window.location.href = "/labels";
                }
            });
        }
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={15} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />                            
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Etiquetas" subtitle="Agregar nueva etiqueta"/>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card card-small mb-4">
                                            <div className="card-header border-bottom">
                                                <h6 className="m-0">Nueva etiqueta</h6>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item p-3">
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-4">
                                                                    <label for="name">Nombre</label>
                                                                    <input type="text" className="form-control" id="name" placeholder="Ingrese nombre" />
                                                                </div>
                                                            </div>                                                            
                                                            <div className="card-footer border-top">
                                                                <button onClick={() => this.onClickSave()} 
                                                                        type="button" 
                                                                        className="mb-2 btn btn-primary mr-2" 
                                                                        style={{float: 'right'}} 
                                                                        disabled={this.state.disabled}>
                                                                        Guardar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default AddLabelPage;