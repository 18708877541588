import React, { Component } from 'react';

import ReactQuill from 'react-quill'; 
import Cropper from "react-cropper";

import 'react-quill/dist/quill.snow.css';
import '../styles/ReactQuill.css'
import "cropperjs/dist/cropper.css";
import GridImagesComponent from '../components/GridImagesComponent';
import firebase from '@firebase/app';
import '@firebase/storage';
import { getProviders } from '../utils/functions';

var config = process.env;

class FormBoxGameComponent extends Component {
    
	constructor(){
        super();
        this.state = { 
            editMode: false,
            text: '',
            prefix: '',
            code: '',
            images: {},
            externalIds: [],
            products: [],
            relatedProduct1: -1,
            relatedProduct2: -1,
            relatedProduct3: -1,
            currentStock: 0,
            providers: []
        }
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
    }

    async componentDidMount() {       

        let providers = await getProviders("product")
        this.setState({providers: providers })

        if (this.props.code != undefined && this.props.code != '') {
            this.fetchData(this.props.code)
        } else {
            this.fetchRelatedProduct();
        }

        document.addEventListener("wheel", function(event){
            if(document.activeElement.type === "number"){
                document.activeElement.blur();
            }
        });
    }

    fetchRelatedProduct = () => {
        //Load RelatedProducts
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                var list = [];
                data.data.forEach(item => {
                    list.push({
                        code: item.code,
                        name: item.name
                    });
                });
                this.setState({ products: list });
                this.loadRelatedProducts(list);
            }
        });
    }

    loadRelatedProducts = (list) => {
        var x1 = document.getElementById("relatedProduct1");
        var x2 = document.getElementById("relatedProduct2");
        var x3 = document.getElementById("relatedProduct3");
        list.forEach(item => {
            var option1 = document.createElement("option");
            var option2 = document.createElement("option");
            var option3 = document.createElement("option");
            option1.text = item.name;
            option1.value = item.code;
            if (this.state.relatedProduct1 == item.code) {
                option1.defaultSelected = true
            }

            option2.text = item.name;
            option2.value = item.code;
            if (this.state.relatedProduct2 == item.code) {
                option2.defaultSelected = true
            }

            option3.text = item.name;
            option3.value = item.code;
            if (this.state.relatedProduct3 == item.code) {
                option3.defaultSelected = true
            }

            if (item.code != this.state.code) {
                x1.add(option1);
                x2.add(option2);
                x3.add(option3);
            }
        });
    }

    fetchData = (code) => {
        var request = {
            method: 'GET',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'product/' + code, request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                if (data.data.relatedProducts.length == 1) {
                    this.setState({ relatedProduct1: data.data.relatedProducts[0].code });
                } else if (data.data.relatedProducts.length == 2) {
                    this.setState({ 
                        relatedProduct1: data.data.relatedProducts[0].code,
                        relatedProduct2: data.data.relatedProducts[1].code
                    });
                } else if (data.data.relatedProducts.length == 3) {
                    this.setState({ 
                        relatedProduct1: data.data.relatedProducts[0].code,
                        relatedProduct2: data.data.relatedProducts[1].code,
                        relatedProduct3: data.data.relatedProducts[2].code
                    });
                }
                this.setState({
                    externalIds: data.data.externalItems != null ? data.data.externalItems : [],
                    currentStock: data.data.product.stock
                })
                this.loadData(data.data.product);
                this.fetchRelatedProduct();
            }
        });

        //Load images
        const ref = firebase.storage().ref(`/products/${code}`);
        ref.listAll().then(dir => {
            dir.items.forEach(item => {
                firebase.storage().ref(item.fullPath).getDownloadURL().then((url) => {
                    // This can be downloaded directly:
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        var reader = new FileReader();
                        reader.onloadend = () => {
                            var imagesMap = this.state.images;
                            imagesMap[item.name] = {
                                path: reader.result,
                                position: item.name
                            }
                            this.setState({ images: imagesMap });
                        }
                        reader.readAsDataURL(xhr.response);
                    };
                    xhr.onerror = (event) => {
                        console.log(event);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                }).catch((error) => {
                    console.log(error);
                })
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    loadData = (data) => {
        document.getElementById("code").value = data.prefix + data.code;
        document.getElementById("name").value = data.name;
        document.getElementById("price").value = data.price;
        document.getElementById("discount").value = data.discount;
        if (this.props.role == 1) {
            document.getElementById("stock").value = data.stock;   
        }
        document.getElementById("minStock").value = data.minStock;
        document.getElementById("suggestionStock").value = data.suggestionStock;
        document.getElementById("minPlayers").value = data.minPlayers;
        document.getElementById("maxPlayers").value = data.maxPlayers;
        document.getElementById("category").value = data.category;
        document.getElementById("gameDuration").value = data.gameDuration;
        document.getElementById("difficulty").value = data.difficulty;
        document.getElementById("cooperative").value = data.cooperative == 1;
        document.getElementById("lonely").value = data.lonely == 1;
        document.getElementById("forTwo").value = data.forTwo == 1;
        document.getElementById("expansion").value = data.expansion == 1;
        document.getElementById("onlyUse").value = data.onlyUse == 1;
        document.getElementById("provider").value = data.provider;
        document.getElementById("type").value = data.type;
        document.getElementById("sku").value = data.sku;
        document.getElementById("bar_code").value = data.barCode;
        document.getElementById("display").value = data.display == 1;
        document.getElementById("cost").value = data.cost;
        document.getElementById("popularidad").value = data.popularity;
        this.setState({
            editMode: true,
            text: data.description,
            prefix: data.prefix,
            code: data.code,
        });
    }

    handleChangeDescription(value) {
        this.setState({ text: value })
    }

    checkIsValid = (input) => {
        if (input.value == null || input.value == undefined || input.value == '') {
            input.className = "form-control is-invalid";
            return true;
        } else {
            input.className = "form-control";
            return false;
        }
    }

    onClickSave = () => {
        // Establezco la primer img como thumbnail
        let images = {};
        let thumb_pos = 100;
        let thumb_img = "";
        let mapImages = this.state.images;
        Object.keys(mapImages).forEach((key) => {
            if (mapImages[key].path != undefined && mapImages[key].path != '') {
                images[mapImages[key].position] = mapImages[key];
                if (mapImages[key].position != "thumbnail" && parseInt(mapImages[key].position) < parseInt(thumb_pos)) {
                    thumb_pos = mapImages[key].position;
                    thumb_img = mapImages[key].path;
                }
            }
        });
        if (thumb_pos != 100) {
            images["thumbnail"] = { path: thumb_img, position: "thumbnail" }
        }
        
        var name = document.getElementById("name");
        var price = document.getElementById("price");
        var minStock = document.getElementById("minStock");
        var suggestionStock = document.getElementById("suggestionStock");
        var minPlayers = document.getElementById("minPlayers");
        var maxPlayers = document.getElementById("maxPlayers");
        var category = document.getElementById("category");
        var gameDuration = document.getElementById("gameDuration");
        var difficulty = document.getElementById("difficulty");
        var cooperative = document.getElementById("cooperative");
        var lonely = document.getElementById("lonely");
        var forTwo = document.getElementById("forTwo");
        var expansion = document.getElementById("expansion");
        var onlyUse = document.getElementById("onlyUse");
        var provider = document.getElementById("provider").value;
        var type = document.getElementById("type").value;
        var discount = document.getElementById("discount");
        var sku = document.getElementById("sku");
        var display = document.getElementById("display");
        var cost = document.getElementById("cost");
        var popularidad = document.getElementById("popularidad");
        var barCode = document.getElementById("bar_code");
        var rp1 = document.getElementById("relatedProduct1");
        var rp2 = document.getElementById("relatedProduct2");
        var rp3 = document.getElementById("relatedProduct3");
        var prefix = this.state.prefix;
        if (prefix === '')
        {            
            if (type == "1"){
                prefix = 'BG';
            } else if (type == "2"){
                prefix = 'CG';
            } else {
                prefix = 'AC';
            }
            if (provider == "1"){
                prefix += 'D-';
            } else if (provider == "2"){
                prefix += 'A-';
            } else if (provider == "3"){
                prefix += 'DA-';
            } else if (provider == "4"){
                prefix += 'MG-';
            } else {
                prefix += 'O-';
            }
        }
        
        var hasError = false;
        hasError = this.checkIsValid(name) || hasError;
        hasError = this.checkIsValid(price) || hasError;
        hasError = this.checkIsValid(minStock) || hasError;
        hasError = this.checkIsValid(suggestionStock) || hasError;
        hasError = this.checkIsValid(minPlayers) || hasError;
        hasError = this.checkIsValid(maxPlayers) || hasError;
        hasError = this.checkIsValid(gameDuration) || hasError;
        hasError = this.checkIsValid(discount) || hasError;
        hasError = this.checkIsValid(sku) || hasError;
        hasError = this.checkIsValid(barCode) || hasError;

        var rpError = false;
        if (!rpError && rp1.value == rp2.value && rp1.value == rp3.value && rp1.value != -1) {
            rp1.className = "custom-select is-invalid";
            rp2.className = "custom-select is-invalid";
            rp3.className = "custom-select is-invalid";
            rpError = true;
        } else if (!rpError) {
            rp1.className = "custom-select";
            rp2.className = "custom-select";
            rp3.className = "custom-select";
        }
        if (!rpError && rp1.value == rp2.value && rp1.value != -1) {
            rp1.className = "custom-select is-invalid";
            rp2.className = "custom-select is-invalid";
            rpError = true;
        } else if (!rpError) {
            rp1.className = "custom-select";
            rp2.className = "custom-select";
        }
        if (!rpError && rp2.value == rp3.value && rp2.value != -1) {
            rp2.className = "custom-select is-invalid";
            rp3.className = "custom-select is-invalid";
            rpError = true;
        } else if (!rpError) {
            rp2.className = "custom-select";
            rp3.className = "custom-select";
        }
        if (!rpError && rp1.value == rp3.value && rp1.value != -1) {
            rp1.className = "custom-select is-invalid";
            rp3.className = "custom-select is-invalid";
            rpError = true;
        } else if (!rpError) {
            rp1.className = "custom-select";
            rp3.className = "custom-select";
        }

        if (parseInt(discount.value) > 100 || parseInt(discount.value) < 0 ) {
            hasError = true
            discount.className = "form-control is-invalid";
        }

        var newStock = this.state.currentStock;
        if (this.props.role == 1) {
            var newStockItem = document.getElementById("stock");
            hasError = this.checkIsValid(newStockItem) || hasError;
            if (!hasError) {
                newStock = newStockItem.value
            }
        }
        
        if (!hasError && !rpError) {
            var rpList = [];

            if (rp1.value != -1) { rpList.push(parseInt(rp1.value)); }
            if (rp2.value != -1) { rpList.push(parseInt(rp2.value)); }
            if (rp3.value != -1) { rpList.push(parseInt(rp3.value)); }
            
            this.props.onChangeData({
                code: this.state.code,
                prefix: prefix,
                name: name.value,
                price: price.value,
                description: this.state.text,
                stock: newStock,
                minStock: minStock.value,
                suggestionStock: suggestionStock.value,
                minPlayers: minPlayers.value,
                maxPlayers: maxPlayers.value,
                category: category.value,
                gameDuration: gameDuration.value,
                difficulty: difficulty.value,
                cooperative: cooperative.value,
                lonely: lonely.value,
                forTwo: forTwo.value,
                expansion: expansion.value,
                onlyUse: onlyUse.value,
                provider: provider,
                type: type,
                images: images,
                relatedProducts: rpList,
                externalItems: this.state.externalIds,
                discount: discount.value,
                sku: sku.value,
                barCode: barCode.value,
                codeML: "",
                display: display.value,
                cost: cost.value,
                popularidad: popularidad.value
            });
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    /* Images */
    onCropperInit(cropper) {
        this.cropper = cropper;
    }

    cropImage = () => {
        if (typeof this.cropper.getCroppedCanvas() === "undefined" || this.cropper.getCroppedCanvas() === null) { return; }

        var mapImages = this.state.images;

        var url = this.cropper.getCroppedCanvas({
            maxWidth: 2048,
            maxHeight: 2048,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'high',
        }).toDataURL('image/jpeg');

        if (mapImages[1] == undefined || mapImages[1] == "") {
            mapImages[1] = { path: url, position: 1 }
        }  
        else if (mapImages[2] == undefined || mapImages[2] == "") {
            mapImages[2] = { path: url, position: 2 }
        } 
        else if (mapImages[3] == undefined || mapImages[3] == "") {
            mapImages[3] = { path: url, position: 3 }
        } 
        else if (mapImages[4] == undefined || mapImages[4] == "") {
            mapImages[4] = { path: url, position: 4 }
        } 
        else if (mapImages[5] == undefined || mapImages[5] == "") {
            mapImages[5] = { path: url, position: 5 }
        } 
        else if (mapImages[6] == undefined || mapImages[6] == "") {
            mapImages[6] = { path: url, position: 6 }
        } 

        this.setState({ images: mapImages });
    }

    rotateImage = (rotate) => {
        if (typeof this.cropper.getCroppedCanvas() === "undefined" || this.cropper.getCroppedCanvas() === null) { return; }
        this.cropper.rotate(rotate);    
    }

    onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        try {
            const reader = new FileReader();
            reader.onload = () => {
              this.setState({ src: reader.result });
            };
            reader.readAsDataURL(files[0]);
        } catch (error) {}
    }

    handleClickFile = () => {
        document.getElementById("file").click();
    }

    handleUpdatePositions = (imagesUpdated) => {
        this.setState({ images: imagesUpdated})
    }

    handleImagesDeleted = (position) => {
        var newList = this.state.images;
        newList[position] = '';
        this.setState({ images: newList });
    }

    addExternalId = () => {
        var newList = this.state.externalIds;
        newList.push("New");
        this.setState({ externalIds: newList });
    }

    handleExternalIdChange = (index, event) => {
        let data = [...this.state.externalIds];
        data[index] = event.target.value;
        this.setState({ externalIds: data });
     }

     deleteExternalId = (index) => {
        let data = [...this.state.externalIds];
        data.splice(index, 1)
        this.setState({ externalIds: data });
     }
     

    showExternalIds = () => {    
        return (
            <div className="form-row mx-4"> 
                {
                this.state.externalIds.map((externalId, index) => {      
                        return (
                        <div className="form-row">
                            <div key={index} className="form-group col-md-8">
                                <input type="text" className="form-control" value= {externalId} onChange={event => this.handleExternalIdChange(index, event)}/>
                            </div>
                            
                            <div className="form-group col-md-4">
                                <button type="button" onClick={() => this.deleteExternalId(index)} class="mb-2 btn btn-outline-danger mr-2">X</button>
                            </div>
                        </div>
                        )
                }) 
                }
            </div> 
        )
    }

    renderProviders = () => {
        return (
        <select id="provider" className="custom-select">
        {   this.state.providers.map(provider => (
                <option key={provider.providerId} value={provider.providerId}>
                    {provider.name}
                </option>
            ))}
        </select>)
        
    }


	render() {
        return (  
            <div className="col-lg-11 mx-auto mt-4">
                <div className="card card-small edit-user-details mb-4">
                    <div className="card-body p-0">
                        <form action="#" className="py-4">
                            <div className="form-row mx-4">
                                <div className="col mb-3">
                                    <h6 className="form-text m-0">Detalles generales del producto</h6>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-2">
                                    <label>Código</label>
                                    <input type="text" className="form-control" id="code" placeholder="Código" defaultValue={this.state.prefix + this.state.code} disabled/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Nombre</label>
                                    <input type="text" className="form-control" id="name" placeholder="Ingrese nombre del juego (*)"/>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Tipo</label>
                                    <select id="type" className="custom-select">
                                        <option value="1">Juego de mesa</option>
                                        <option value="2">Juego de carta</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Popularidad</label>
                                    <select id="popularidad" className="custom-select" defaultValue="3">
                                        <option value="1">Muy baja</option>
                                        <option value="2">Baja</option>
                                        <option value="3">Normal</option>
                                        <option value="4">Alta</option>
                                        <option value="5">Muy alta</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-3">
                                    <label>Proveedor</label>
                                    {this.renderProviders()}
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Precio</label>
                                    <div className="input-group input-group-seamless">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="material-icons">attach_money</i>
                                            </div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="price" placeholder="Precio del producto (*)"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Descuento</label>
                                    <div className="input-group input-group-seamless">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                %
                                            </div>
                                        </div>
                                        <input type="number" min="0" className="form-control" id="discount" placeholder="Descuento del producto (*)"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3" style={{display: (this.props.role == 1 && this.state.editMode) ? 'block' : 'none'}}>
                                    <label>Costo total</label>
                                    <div className="input-group input-group-seamless">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                $
                                            </div>
                                        </div>
                                        <input disabled={true} type="number" min="0" className="form-control" id="cost" placeholder="Costo del producto (*)"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Código de barras</label>
                                    <div className="input-group input-group-seamless">
                                        <input type="number" min="0" className="form-control" id="bar_code" placeholder="Código de barras del producto (*)"/>
                                    </div>
                                </div>
                                <div className="form-group col-md-3">
                                    <label>SKU</label>
                                    <div className="input-group input-group-seamless">
                                        <input type="text" min="0" className="form-control" id="sku" placeholder="SKU del producto (*)"/>
                                    </div>
                                </div>
                                {/* <div className="form-group col-md-3">
                                    <label>Code ML</label>
                                    <div className="input-group input-group-seamless">
                                        <input type="text" min="0" className="form-control" id="code-ml" placeholder="Código de Mercado Libre"/>
                                    </div>
                                </div> */}
                                <div className="form-group col-md-3">
                                    <label>Mostrar en la WEB</label>
                                    <select id="display" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                            </div>
                            <ReactQuill
                                value={ this.state.text }
                                placeholder="Ingrese la descripción del producto"
                                onChange={ this.handleChangeDescription }
                            />
                            <hr />
                            <div className="form-row mx-4">
                                <div className="col mb-3">
                                    <h6 className="form-text m-0">Stock del producto</h6>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                {this.props.role == 1 && (
                                    <div className="form-group col-md-4">
                                        <label>Stock</label>
                                        <input type="number" min="0" className="form-control" id="stock" placeholder="Stock actual (*)"/>
                                    </div>
                                )}
                                <div className="form-group col-md-4">
                                    <label>Stock mínimo</label>
                                    <input type="number" min="0" className="form-control" id="minStock" placeholder="Stock mínimo (*)"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Stock sugerido</label>
                                    <input type="number" min="0" className="form-control" id="suggestionStock" placeholder="Stock sugerido (*)"/>
                                </div>
                            </div>
                            <hr />
                            <div className="form-row mx-4">
                                <div className="col mb-3">
                                    <h6 className="form-text m-0">Características del producto</h6>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-4">
                                    <label>Min de jugadores</label>
                                    <input type="number" min="0" className="form-control" id="minPlayers" placeholder="Cantidad mínima (*)"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Max de jugadores</label>
                                    <input type="number" min="0" className="form-control" id="maxPlayers" placeholder="Cantidad máxima (*)"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Categoría</label>
                                    <select id="category" className="custom-select" defaultValue="1">
                                        <option value="1">Euro</option>
                                        <option value="2">Party</option>
                                        <option value="3">Familiar</option>
                                        <option value="4">Abstracto</option>
                                        <option value="5">Temático</option>
                                        <option value="6">Investigación</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-4">
                                    <label>Duración de la partida</label>
                                    <input type="number" min="0" className="form-control" id="gameDuration" placeholder="Duración en minutos (*)"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Dificultad</label>
                                    <select id="difficulty" className="custom-select">
                                        <option value="1">Fácil</option>
                                        <option value="2">Moderada</option>
                                        <option value="3">Alta</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Es expansión</label>
                                    <select id="expansion" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-4">
                                    <label>Cooperativo</label>
                                    <select id="cooperative" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Solitario</label>
                                    <select id="lonely" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Ideal para dos</label>
                                    <select id="forTwo" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-4">
                                    <label>Único uso</label>
                                    <select id="onlyUse" className="custom-select">
                                        <option value="true" >Si</option>
                                        <option value="false" selected>No</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <div className="form-row mx-4">
                                <div className="col mb-3">
                                    <h6 className="form-text m-0">Productos relacionados</h6>
                                </div>
                            </div>
                            <div className="form-row mx-4">
                                <div className="form-group col-md-4">
                                    <label>Producto 1</label>
                                    <select id="relatedProduct1" className="custom-select">
                                        <option value="-1">- Ninguno -</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Producto 2</label>
                                    <select id="relatedProduct2" className="custom-select">
                                        <option value="-1">- Ninguno -</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Producto 3</label>
                                    <select id="relatedProduct3" className="custom-select">
                                        <option value="-1">- Ninguno -</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-row mx-4">
                                <div className="col mb-3">
                                    <h6 className="form-text m-0">Identificadores de ML</h6>
                                </div>
                            </div>
                            <div id = "ML-Ids" style={{paddingLeft:'5px'}} >            
                                {this.showExternalIds()}                    
                                <div className="form-row form-group mx-4 ">
                                    <button onClick={() => this.addExternalId()} style={{width: "140px"}} type="button" class="mb-2 btn btn-outline-dark mr-2">Agregar Id de ML</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />
                    <div className="form-row mx-4">
                        <div className="col mb-3">
                            <h6 className="form-text m-0">Imágenes del producto</h6>
                        </div>
                    </div>
                    <div className="form-row mx-4">
                        <div className="form-group col-md-6">
                            <Cropper
                                style={{ height: 500, width: "100%", background: "#eaeaea"}}
                                aspectRatio={1}
                                guides={true}
                                src={this.state.src}
                                viewMode={1}
                                dragMode="move"
                                rotatable={true}
                                cropBoxMovable={false}
                                onInitialized={this.onCropperInit.bind(this)} />
                            <div className="form-row mx-4" style={{marginTop: '15px'}}>
                                <div className="form-group col-md-3">
                                    <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={ this.onChange }/>
                                    <button onClick={() => this.handleClickFile()} style={{width: "100%"}} type="button" class="mb-2 btn btn-outline-primary mr-2">Agregar</button>
                                </div>
                                <div className="form-group col-md-3"></div>
                                <div className="form-group col-md-3">
                                    <button onClick={() => this.rotateImage(90)} style={{width: "100%"}} type="button" class="mb-2 btn btn-outline-dark mr-2">Rotar</button>
                                </div>
                                <div className="form-group col-md-3">
                                    <button onClick={() => this.cropImage()} style={{width: "100%"}} type="button" class="mb-2 btn btn-outline-success mr-2">Confirmar</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <GridImagesComponent  
                                images={this.state.images}
                                onUpdatePositions={ this.handleUpdatePositions }
                                onImageDeleted={ this.handleImagesDeleted } />
                        </div>
                    </div>

                    <div className="mb-3 progress" style={{height: "5px"}}>
                        <div className="progress-bar bg-danger" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{width: `${this.props.uploadValue}%`}} />
                    </div>

                    <div className="card-footer border-top">
                        <button onClick={() => this.onClickSave()} type="button" className="mb-2 btn btn-primary mr-2" style={{float: 'right'}} disabled={this.props.disabled}>Guardar</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormBoxGameComponent;