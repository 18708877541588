import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import TableAdminsComponent from '../components/TableAdminsComponent';
import HeaderComponent from '../components/HeaderComponent';
var config = process.env;

class LoginPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0
        }
	}

	componentWillMount() {
        this.validateSession();
    }
    
    validateSession = () => {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            }
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/checkSessionAdmin', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                if (data.data.isValidToken) {
                    if (data.data.role == 2) {
                        window.location.href = config.REACT_APP_REDIRECT_BOX_GAME;
                    } else {
                        this.setState({ checked: true, role: data.data.role });   
                    }
                } else {
                    window.location.href = config.REACT_APP_REDIRECT_LOGIN;
                }
            }
        });
    }

    addAdmin = () => {
        window.location.href = "/addAdmin";
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={7} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Dashboard" subtitle="Administradores"/>
                                <TableAdminsComponent />
                            </div>
                        </main>
                    </div>
                    <div onClick={() => this.addAdmin()} class="color-switcher-toggle animated pulse infinite">
                        <i class="material-icons">add</i>
                    </div>
                </div>
            );
        }
    }
}

export default LoginPage;