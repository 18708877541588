import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';


var config = process.env;

class CloseCashComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',      
      pos: '',      
      retirement: '',
      errorClosing: null,
      observations: ''
    };
  }

    handleChange = (e) => {    
        const name = e.target.name;
        const value = e.target.value.replace(/[^\d,]/g, '');
        const cleanedValue = value.replace(/,/g, '.');
        this.setState({ [name]: value.trim() });
    };

    handleObservationsChange = (e) => {
        const name = e.target.name;
        this.setState({ [name]: e.target.value.trim() });
    }

    formatNumber = (num) => {
        if (num === '') return '';
        const parsedNumber = parseFloat(num.replace(/\./g, ''));
        if (isNaN(parsedNumber)) return '';
        return `$${parsedNumber.toLocaleString('de-DE')}`;
    };

closeCashRegister = () => {
    if (this.state.amount == '')
    {
        const inputAmount = document.getElementById('amount');
        inputAmount.classList.add("is-invalid");
    }
    else
    {
        var request = {
            method: 'POST',
            headers: { 
                'access-token': localStorage.getItem('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                close_money: this.state.amount,
                close_pos: this.state.pos,
                retirement: this.state.retirement,
                observations: this.state.observations
            })
        };
        fetch(config.REACT_APP_BASE_URL + config.REACT_APP_VERSION + 'session/closeCashRegister', request)
        .then(results => { return results.json(); })
        .then(data => {
            if (data.error > 0) {
                alert(data.message);
                if (data.error == 1002) {
                    window.location.href = localStorage.getItem('homePageURL');
                }
            } else {
                if (data.error == 0 && data.data.status == 0) {
                    window.location.href = "/caja";
                } else 
                {
                    this.setState({ errorClosing: data.data.mustCloseWith.toString() });
                }
            }            
        });
    }
}

  render() {
    return (
        <div className="col-lg-12">
                    <HeaderComponent title="Caja" subtitle="Cierre de caja"/>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-3 card card-small mb-4" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                            <div className="row">
                                <div className="col">  
                                    <span class="text-muted d-block mb-2 text-center">Datos</span>
                                    <div className="col-md-4 d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                        <label for="date">Fecha: </label>
                                        <span class="text-muted d-block mb-2">{new Date().toLocaleDateString()}</span>
                                    </div>  
                                    <div className="col-md-4 d-flex align-items-center" style={{gap: '3px', fontSize: '20px'}}>
                                        <label for="user">Usuario: </label>
                                        <span class="text-muted d-block mb-2">{localStorage.getItem('firstName')}</span>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                <div class="text-center">
                                    <span class="text-muted d-block mb-2">Dinero en caja</span>
                                    <div>
                                        <input 
                                        id= "amount"
                                        type="text" 
                                        name='amount'
                                        className="form-control mb-3 mx-auto"
                                        value={this.formatNumber(this.state.amount)}
                                        onChange={this.handleChange} 
                                        placeholder="$0.0" 
                                        style={{ fontSize: '24px', textAlign: 'center'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                <div class="text-center">
                                    <span class="text-muted d-block mb-2">Cierre de lote</span>
                                    <div>
                                        <input 
                                        id= "pos"
                                        type="text" 
                                        name='pos'
                                        className="form-control mb-3 mx-auto"
                                        value={this.formatNumber(this.state.pos)}
                                        onChange={this.handleChange} 
                                        placeholder="$0.0" 
                                        style={{ fontSize: '24px', textAlign: 'center'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                <div class="text-center">
                                    <span class="text-muted d-block mb-2">Retiros</span>
                                    <div>
                                        <input 
                                        id= "retirement"
                                        type="text" 
                                        name='retirement'
                                        className="form-control mb-3 mx-auto"
                                        value={this.formatNumber(this.state.retirement)}
                                        onChange={this.handleChange} 
                                        placeholder="$0.0" 
                                        style={{ fontSize: '24px', textAlign: 'center'}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.errorClosing?
                        <div>
                            <div class="card card-small mb-12" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                <h4 className="error__content text-error">Error en el cierre: Dinero en caja debería ser {this.formatNumber(this.state.errorClosing)} para que cierre correctamente. Verificar nuevamente los valores ingresados.</h4>
                            </div>
                            <br></br>
                            <div class="card card-small mb-4 pt-3" style={{ borderRadius: '0.625rem', padding: '15px'}}>
                                    <div class="text-center">
                                        <span class="text-muted d-block mb-2">Observaciones</span>
                                        <div>
                                            <input 
                                            id= "observations"
                                            type="text" 
                                            name='observations'
                                            className="form-control mb-3 mx-auto"
                                            onChange={this.handleObservationsChange} 
                                            style={{ fontSize: '18px', textAlign: 'center'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : ''}
                    <br></br>
                    <div className="row align-items-center justify-content-center">
                        <button onClick={() => this.closeCashRegister()} type="button" className={`mb-2 btn ${this.state.observations ? 'btn-danger' : 'btn-primary'} mr-2`} style={{float: 'right', fontSize: '25px'}} disabled={this.props.disabled}>{this.state.observations ? "Cerrar Caja con Observaciones" : "Cerrar Caja"}</button>
                    </div>
             </div>
      
    );
  }
}

export default CloseCashComponent;
