import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import ProductAnalyticsPage from './pages/ProductAnalyticsPage';
import GeneralStatistics from './pages/GeneralStatistics';
import BoxGamesPage from './pages/BoxGamesPage';
import UsersPage from './pages/UsersPage';
import AccessoriesPage from './pages/AccessoriesPage';
import ProfilePage from './pages/ProfilePage';
import TicketPage from './pages/TicketPage';
import AddBoxGamePage from './pages/AddBoxGamePage';
import AdminsPage from './pages/AdminsPage';
import EditBoxGamePage from './pages/EditBoxGamePage';
import AddAccesoryPage from './pages/AddAccesoryPage';
import EditAccesoryPage from './pages/EditAccesoryPage';
import AddAdminPage from './pages/AddAdminPage';
import CredentialsPage from './pages/CredentialsPage';
import PurchasePage from './pages/PurchasePage';
import AddPurchasePage from './pages/AddPurchasePage';
import AddDiscountCode from './pages/AddDiscountCodePage';
import ShippingPage from './pages/ShippingPage';
import ProviderPage from './pages/ProviderPage';
import AddUserPage from './pages/AddUserPage';
import RestockingPage from './pages/RestockingPage';
import MovementsPage from './pages/MovementsPage';
import CajaPage from './pages/CajaPage';
import DiscountCodesPage from './pages/DiscountCodesPage';
import RefreshTokenPage from './pages/RefreshTokenPage';
import AddRestockingPage from './pages/AddRestockingPage';
import AddInvoicePage from './pages/AddInvoicePage';
import InvoicesPage from './pages/InvoicesPage';
import LabelsPage from './pages/LabelsPage';
import AddLabelPage from './pages/AddLabelPage';
import LabelAssignPage from './pages/LabelAssignPage';
import SoldProductsPage from './pages/SoldProductsPage';
import ConfigurationPage from './pages/ConfigurationPage';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/analytics';

var config = process.env;

const firebaseConfig = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  authDomain: config.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: config.REACT_APP_FIREBASE_APP_ID,
  measurementId: config.REACT_APP_FIREBASE_MEASUREMENT_ID 
}
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const Router = () => {
  return (
    <PrimeReactProvider>
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path={"/"} component={LoginPage} />
        <Route exact path={"/generalStatistics"} component={GeneralStatistics} />
        
        <Route exact path={"/credentials"} component={CredentialsPage} />

        <Route exact path={"/admin"} component={AdminsPage} />
        <Route exact path={"/addAdmin"} component={AddAdminPage} />

        <Route exact path={"/boxGames"} component={BoxGamesPage} />
        <Route exact path={"/addBoxGame"} component={AddBoxGamePage} />
        <Route exact path={"/editBoxGame"} component={EditBoxGamePage} />

        <Route exact path={"/accessories"} component={AccessoriesPage} />
        <Route exact path={"/addAccessory"} component={AddAccesoryPage} />
        <Route exact path={"/editAccessory"} component={EditAccesoryPage} />

        
        <Route exact path={"/invoices"} component={InvoicesPage} />
        <Route exact path={"/addInvoice"} component={AddInvoicePage} />

        <Route exact path={"/productAnalytics"} component={ProductAnalyticsPage} />        
        
        <Route exact path={"/providers"} component={ProviderPage} />
        <Route exact path={"/discountCodes"} component={DiscountCodesPage} />
        <Route exact path={"/users"} component={UsersPage} />
        <Route exact path={"/addUser"} component={AddUserPage} />
        <Route exact path={"/accessories"} component={AccessoriesPage} />
        <Route exact path={"/tickets"} component={TicketPage} />
        <Route exact path={"/profile"} component={ProfilePage} />
        <Route exact path={"/purchase"} component={PurchasePage} />
        <Route exact path={"/shipping"} component={ShippingPage} />
        <Route exact path={"/addPurchase"} component={AddPurchasePage} />
        <Route exact path={"/addDiscountCode"} component={AddDiscountCode} />
        <Route exact path={"/caja"} component={CajaPage} />
        <Route exact path={"/restocking"} component={RestockingPage} />
        <Route exact path={"/addRestocking"} component={AddRestockingPage} />
        <Route exact path={"/movements"} component={MovementsPage} />

        <Route exact path={"/labels"} component={LabelsPage} />
        <Route exact path={"/addLabel"} component={AddLabelPage} />
        <Route exact path={"/labelAssign"} component={LabelAssignPage} />


        <Route exact path={"/soldProducts"} component={SoldProductsPage} />
        <Route exact path={"/configurations"} component={ConfigurationPage} />

        <Route exact path={"/refreshTokenMP"} component={RefreshTokenPage} />
      </Switch>
    </BrowserRouter>
    
    </PrimeReactProvider>
  );
}


export default Router;