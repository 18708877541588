import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import PortadaImagesComponent from '../components/PortadaImagesComponent';
import { validateSession } from  '../utils/functions';

class ConfigurationPage extends Component {

	constructor(){
        super();
        this.state = {
            checked: false,
            role: 0
        }
	}

	async componentDidMount() {
        let role = await validateSession()
        this.setState({ checked: true, role:  role});
    }

    addUser = () => {
        window.location.href = "/addUser";
    }
    
	render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div class="container-fluid">
                    <div class="row">
                        <SidebarComponent itemSelected={18} role={this.state.role}/>
                        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Dashboard" subtitle="Configuraciones"/>
                                <PortadaImagesComponent />
                            </div>
                        </main>
                    </div>
                </div>
            );
        }
    }

}

export default ConfigurationPage;