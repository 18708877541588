import React, { Component } from 'react';

import SidebarComponent from '../components/SidebarComponent';
import NavbarComponent from '../components/NavbarComponent';
import HeaderComponent from '../components/HeaderComponent';
import TableTicketComponent from '../components/TableTicketComponent';
import { validateSession } from  '../utils/functions';

class TicketPage extends Component {

    constructor(){
        super();
        this.state = {
            checked: false,
            role: 0,
            mode: 0,
            selectedTicketsAmmount: 0,
            showModal: false
        }
        this.childRef = React.createRef();
    }

    async componentDidMount() {
        let role = await validateSession();
        this.setState({ checked: true, role: role });
    }

    selectTickets = () => {
        this.setState({ mode: 1, showModal: false });
    }

    setSelectedTicketsAmmount = (newValue) => {
        this.setState({ selectedTicketsAmmount: newValue });
    }

    openModal = () => {
        this.setState({ showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    renderModalTicketInvoiceType = () => {
        if (!this.state.showModal) return null;

        return (
            <div className="modal fade show" style={{ display: 'block' }} role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{textAlign: "center"}}>Seleccione modo de facturación</h4>
                        </div>
                        <div className="modal-body">
                            <select id="ticket_invoice_status" className="custom-select" defaultValue="1">
                                <option value="1">Facturar</option>
                                <option value="2">Ignorar</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button 
                                className="mb-2 mr-1 btn btn-outline-secondary btn-sm" 
                                onClick={this.closeModal}>
                                Cancelar
                            </button>
                            <button 
                                className="mb-2 mr-1 btn btn-primary btn-sm" 
                                onClick={() =>  {
                                    var status = document.getElementById("ticket_invoice_status").value;
                                    this.childRef.current.changeMasiveStatus(status);
                                    this.closeModal();
                                }}>
                                Actualizar
                            </button>                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (!this.state.checked) {
            return (<div></div>);
        } else {
            return (  
                <div className="container-fluid">
                    <div className="row">
                        <SidebarComponent itemSelected={6} role={this.state.role}/>
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <NavbarComponent role={this.state.role} />
                            <div className="main-content-container container-fluid px-4 mb-4">
                                <HeaderComponent title="Dashboard" subtitle="Tickets"/>
                                <TableTicketComponent ref={this.childRef} mode={this.state.mode} setSelectedTicketsAmmount={this.setSelectedTicketsAmmount}/>
                            </div>                            
                            {
                            this.state.mode === 0 ? 
                                <div onClick={this.selectTickets} className="color-switcher-toggle animated pulse infinite" style={{right: '40px'}}>
                                    <i className="material-icons">task_alt</i>
                                </div> 
                            : 
                                <div onClick={this.openModal} className="color-switcher-toggle animated pulse infinite" style={{right: '40px'}}>
                                    <i className="material-icons">send</i>
                                </div> 
                            }
                        </main>
                        
                        { this.renderModalTicketInvoiceType() }

                    </div>
                </div>
            );
        }
    }
}

export default TicketPage;
